import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AspectRatioBox from "./aspectRatioUtil";
// import { ReactComponent as ArrowRight } from "../static/svg/arrow-right.svg";
import Loader from '../static/svg/loader.svg';
import { Navigation, Pagination, Mousewheel, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/mousewheel';
import 'swiper/css/free-mode';

import HomeBannerM from '../static/png/UDXD-header-m.webp';
import configData from "../config.json";

// function getMedia(path) {
//   return configData.API_URL + path;
// }


gsap.registerPlugin(ScrollTrigger);

function Home(props) {
    const landingVideo = useRef();
    const landingImg = useRef();
    const [loaded, setLoaded] = useState(false);
    const [capabilities, setCapabilities] = useState();
    const [industries, setIndustries] = useState();
    const [recentWorkIntro, setRecentWorkIntro] = useState();
    const [home, setHome] = useState();
    const [capabilityRow, setCapabilityRow] = useState();

    function getMedia(path, path_m) {
      let r;
      if (props.isMobile) {
        if (path_m) {
          r = configData.API_URL + path_m
        } else {
          r = configData.API_URL + path
        }
       
      } else {
        r = configData.API_URL + path
      }
    
      return r;
    }
    

    const getHome = async() => {
      !home && await axios({
        method: 'get',
        url: configData.API_URL + "/api/home/"
        })
        .then(response => {
          const dataJSON = response.data;
    
          dataJSON && setHome(dataJSON[0]);
          // console.log('capablities set');
        });
    }

    const getCapability = async() => {
      !capabilities && await axios({
        method: 'get',
        url: configData.API_URL + "/api/capability-list/"
        })
        .then(response => {
          const dataJSON = response.data;
          setCapabilities(dataJSON);
          // console.log('capablities set');
        });

      !capabilityRow && await axios({
        method: 'get',
        url: configData.API_URL + "/api/capability-row/1"
        })
        .then(response => {
          const dataJSON = response.data;
          setCapabilityRow(dataJSON);
          console.log('setCapabilityRow set', dataJSON);
        });
    }

    const getRecentWork = async() => {
      !recentWorkIntro && await axios({
        method: 'get',
        url: configData.API_URL + "/api/recent-work/"
        })
        .then(response => {
          const dataJSON = response.data;
          setRecentWorkIntro(dataJSON);
          // console.log('recent work set');
        });
      }

  const getIndustries = async() => {
  
    !industries && await axios({
      method: 'get',
      url: configData.API_URL + "/api/industries/"
      })
      .then(response => {
        const dataJSON = response.data;
        setIndustries(dataJSON);
        
      });
    
    }

    useEffect(() => {
      let rowTl;
      if (recentWorkIntro) {
        
        ScrollTrigger.refresh();
        let rowTl = gsap.timeline({
          defaults: {
            duration: 0.4, 
            ease: "power1",
          },
          scrollTrigger: {
            trigger: "#row1trigger",
            start: "center 80%",
            end: "bottom bottom",
            scrub: 1,
            markers: false
          }
        });
       rowTl.to(".reset1", {opacity: 0, duration: 0})
       rowTl.fromTo(".anim0.row1", {translateY: "40px"}, {translateY: "0"});
       rowTl.fromTo(".anim0.row1", {opacity: 0}, {opacity: 1},"<");
       rowTl.fromTo(".anim0.row1-1a", {translateY: "40px"}, {translateY: "0"});
       rowTl.fromTo(".anim0.row1-1a", {opacity: 0}, {opacity: 1},"<");
       rowTl.fromTo(".anim0.row1-1b", {translateY: "40px"}, {translateY: "0"});
       rowTl.fromTo(".anim0.row1-1b", {opacity: 0}, {opacity: 1},"<");
       rowTl.fromTo(".anim0.row1-1c", {translateY: "40px"}, {translateY: "0"},"<");
       rowTl.fromTo(".anim0.row1-1c", {opacity: 0}, {opacity: 1},"<");
       rowTl.to("#recentWorkSlide0", {opacity: 1}, ">0.4");
       rowTl.to("#recentWorkSlide1", {opacity: 1}, ">");
       rowTl.to("#recentWorkSlide2", {opacity: 1}, ">");
       rowTl.to(".reset1", {opacity: 1, delay: 0}, ">")
    
      }
      return () => {
        rowTl && rowTl.kill();

      }
    }, [recentWorkIntro])

    function handleVideo(video) {
 
   
      video.play();
     
     
      function handleAnim() {
        
        if (video.currentTime >= 7.25) {
      
          gsap.to('.homeTextBlock', {opacity: 1, duration: 0});
          document.querySelectorAll("#logo svg")[0].classList.add('fillWhite');
          // gsap.to('#logo svg', {opacity: 1, duration: 0});
          gsap.to(video, {opacity: 0, duration: 0.75});
        }
      }
    
      video.ontimeupdate = function() {handleAnim()};
    
    
    }

    function handleBanner(){
      setTimeout(() => {
        gsap.to('.homeTextBlock', {opacity: 1, duration: 0});
        document.querySelectorAll("#logo svg")[0].classList.add('fillWhite');
        gsap.to(landingImg.current, {opacity: 0, duration: 0.75});
      }, 1000);
    }
      
    

    useEffect(() => {
      let rowTl;
      if (home && home.homefiles && landingVideo.current && !props.isMobile) {
        let video = landingVideo.current;
        video.defaultMuted = true;
        video.addEventListener("loadstart", handleVideo(video));
      }


      return () => {
        rowTl && rowTl.kill();
      }
    }, [home])
    


    useEffect(() => {
      let rowTl;
      if (capabilityRow) {
         // Anim Sequences
     
          rowTl = gsap.timeline({
            defaults: {
              duration: 0.4, 
              ease: "power1",
            },
            scrollTrigger: {
              trigger: "#row2trigger",
              start: "center 80%",
              end: "top top",
              scrub: 1,
              markers: false
            }
          });
         let gridObjs = document.querySelectorAll('#row2 .anim0');
        //  console.log('gridObjs', gridObjs)
         gridObjs.forEach((obj, i) => {
          rowTl.fromTo(obj, {translateY: "40px"}, {translateY: "0"}, "<");
          rowTl.fromTo(obj, {opacity: 0}, {opacity: 1}, "<");
         });



      }
      return () => {
        rowTl && rowTl.kill();
      }
    }, [capabilityRow])


    useEffect(() => {
      let rowTl;
      if (capabilities) {
         // Anim Sequences
     
          let rowTl = gsap.timeline({
            defaults: {
              duration: 0.4, 
              ease: "power1",
            },
            scrollTrigger: {
              trigger: "#row0trigger",
              start: "center 80%",
              end: "top top",
              scrub: 1,
              markers: false,
              onEnter: getRecentWork
            }
          });
         let gridObjs = document.querySelectorAll('#row0 .anim0');
        //  console.log('gridObjs', gridObjs)
         gridObjs.forEach((obj, i) => {
          let chain;
          if (i>0 && ((i < 3)||(i >= 4))) {
            chain = "<";
          }
          rowTl.fromTo(obj, {translateY: "40px"}, {translateY: "0"}, chain);
          rowTl.fromTo(obj, {opacity: 0}, {opacity: 1},"<");
         });



      }
      return () => {
        rowTl && rowTl.kill();
      }
    }, [capabilities])



    useEffect(() => {
      setLoaded(true);
      props.accentColor('#FFFFFF');
      props.logoColor('');
      gsap.to('#core', {backgroundColor: '#ffffff', duration: 0.3})
      // document.querySelectorAll("#logo svg")[0].classList.add('fillWhite');
      document.querySelectorAll("#logo svg")[0].classList.remove('fillWhite');
      // Page Meta
      document.title = configData.BASE_TITLE
      document.querySelector('meta[name="description"]').setAttribute("content", "");
      document.documentElement.scrollTop = 0;

       // Anim Sequences
       let landingTl = gsap.timeline({
          defaults: {
            duration: 0.6, 
            ease: "power1"
          },
          delay: 0.25
        });
        landingTl.fromTo(".anim1.landing", {translateY: "40px"}, {translateY: "0"});
        landingTl.fromTo(".anim1.landing", {opacity: 0}, {opacity: 1},"<");

      let gridObjs = document.querySelectorAll('#row0 .grid .item');
      gridObjs.forEach((obj, i) => {
        let chain;
        if (i>0 && ((i < 3)||(i >= 4))) {
          chain = "<";
        }
        landingTl.fromTo(obj, {translateY: "40px"}, {translateY: "0"},chain);
        landingTl.fromTo(obj, {opacity: 0}, {opacity: 1},"<");
      });
      getHome();

      // Load Content 0
      getCapability();
      getIndustries();
      
      console.log('home',)
    }, []);


function getHomeFile(id, path, aspectRatio, aspectRatioM, fileType) {
  let span = document.getElementById(id);
  let width = "auto";
  let height = "auto";
  let paddingTop = "auto";
  console.log('getHomeFile', span, id)
  if (span) {
    if (props.isMobile) {
      if (aspectRatioM) {
        width = "100vw"
        paddingTop = 1/aspectRatioM * 100;
      }
    } else {
      if (aspectRatio) {
        width = "100vw"
        paddingTop = 1/aspectRatio * 100;
      }
      span.setAttribute('style', 'width: ' + width + "; padding-top: calc(" + paddingTop + "%)");
      span.parentElement.setAttribute('style', "height: " + height + ";")
    }
    
    

  }
}

function getFileType(string) {

  var ext = string.split('.').pop();
  console.log(ext)
  return ext;
}

function handleBackground(color) {
  gsap.to('#core', {backgroundColor: color, duration: 0.2})
}


  return (
      <CSSTransition in={loaded} timeout={0} unmountOnExit classNames="pageItem">
    
         <div id="home">
          <span id="landingTrigger" className="animTrigger"></span>
          <div id="landing">
          <div className="column homeTextBlock">
          <div className="heading anim1 landing">
            <AspectRatioBox tag={"h1Array"} fonts={props.fonts} content={home && home.heading} isMobile={props.isMobile} />
            <AspectRatioBox tag={"h2Inbox"} classes={['landing']} fonts={props.fonts} content={home && home.text} isMobile={props.isMobile}  />
          </div>
        </div>

          <div className="banners">
         
          <div className={"homeBanner first"} id={'homeBanner_0'}>
            <div className="arBox on">
              <div className="arBoxIn">
              {home && home.homefiles && !props.isMobile &&
                <video ref={landingVideo} playsInline muted>
                  <source  src={getMedia(home.homefiles[0].file)} type={"video/mp4"} />
                </video>}
              {home && home.homefiles && props.isMobile &&
              //   <video poster={HomeBannerM} ref={landingVideo} playsInline muted>
              //   <source src={getMedia(home.homefiles[0].file_m)} type={"video/mp4"} />
              // </video>
                  <img ref={landingImg} rel="preload" src={HomeBannerM} onLoad={() => handleBanner()} alt="udxd banner" />
                }
                  
              </div>
            </div>
          </div>
          </div>
           
            
          </div>

            <div className="row" id="row0">
            <span id="row0trigger" className="animTrigger"></span>

                {capabilities && 
                  <div className="column deliverableRow">              
  
                      <div>
                        <div className="heading short">
                          <h2 className={"row0" + ((props.fonts)?" on":"")}>
                            <span className="">Ways to Engage</span>
                          </h2>
                        </div>
                        <div className="grid grid_30_70">
                          <h3 className="alt">UDXD has created and implemented industry wide solutions that help our clients transform ideas into compelling realities.

  
                          </h3>

                          <div className="grid grid2">
                            {capabilities.map((obj, i) => (
                              <a href={obj.get_absolute_url} key={"serviceRow_" + i}>
                              <h3 className={"translateY row0" + ((props.fonts)?" on":"")}>
                                <span className="">{obj.heading}
                                <span className="icon">
                     
                                <svg width="15.167" height="15.526" viewBox="0 0 15.167 15.526">
                                <defs>
                                  <clipPath id={"clip-path_" + i}>
                                    <path data-name="Path 263" d="M0,0H15.167V-15.526H0Z" fill="none"/>
                                  </clipPath>
                                </defs>
                                <g data-name="Group 723" transform="translate(0 15.526)" clipPath={"url(#clip-path_" + i + ")"}>
                                  <g data-name="Group 721" transform="translate(7.055 -0.354)">
                                    <path data-name="Path 261" d="M0,0,7.405-7.405-.009-14.819" fill="none" stroke={(obj.text_color)?obj.text_color:"#000000"} strokeWidth="1"/>
                                  </g>
                                  <g data-name="Group 722" transform="translate(0 -7.763)">
                                    <path data-name="Path 262" d="M0,0H14.2" fill="none" stroke={(obj.text_color)?obj.text_color:"#000000"}  strokeWidth="1"/>
                                  </g>
                                </g>
                              </svg>
                              </span>
                                </span>
                               
  
                              </h3>
                            
                             
                              </a>
                              
                              ))}
                            </div>
                            </div>
                        
                      </div>
                      
            
                    
                  </div>
                  }
            </div>

            <div className="row" id="row2">
            <span id="row2trigger" className="animTrigger"></span>

                {capabilityRow && 
                  <div className="column deliverableRow">              
  
                      <div>
                        <div className="heading anim0 short">
                          <h2 className={"row2" + ((props.fonts)?" on":"")}>
                            <span className="">{capabilityRow.heading}</span>
                          </h2>
                        </div>
                        <div className="grid grid_30_70">
                          {capabilityRow.servicerowitems.map((obj, i) => (
                            <div key={"serviceRow_" + i}>
                            {(obj.text) &&<h3 className={"anim0 row2" + ((props.fonts)?" on":"") + (obj.text ? " alt":"")  + (" serviceRowImage" + i)}>
                             <span className="">{obj.text}</span>
                          </h3>}
                            <div className="serviceRowImgContainer">
                           
                            {obj.file && <div className="serviceRowImage anim0">
                                <img src={props.isMobile?getMedia(obj.file_m):getMedia(obj.file)} alt="" />
                              </div>}
                            </div>
                            </div>
                            
                            ))}
                            </div>
                        
                      </div>
                      
            
                    
                  </div>
                  }
            </div>

            <div className="row">
              <span id="row1trigger" className="animTrigger1"></span>
              <div className="column">              
                <div>
                  {recentWorkIntro && 
                    <div className="heading">
                        <h2 className={"anim0 reset1 row1 arBox" + ((props.fonts)?" on":"")}>
                          <span className="arBoxIn">{(recentWorkIntro.heading && props.fonts ? (recentWorkIntro.heading):"")}</span>
                        </h2>
                       <a href="/case-studies/" className="translateY"><h3 className="anim0 reset1 row1-1a">{recentWorkIntro.subheading} <span className="icon">
                     
                                <svg width="15.167" height="15.526" viewBox="0 0 15.167 15.526">
                                <defs>
                                  <clipPath id={"clip-path_recent"}>
                                    <path data-name="Path 263" d="M0,0H15.167V-15.526H0Z" fill="none"/>
                                  </clipPath>
                                </defs>
                                <g data-name="Group 723" transform="translate(0 15.526)" clipPath={"url(#clip-path_recent"}>
                                  <g data-name="Group 721" transform="translate(7.055 -0.354)">
                                    <path data-name="Path 261" d="M0,0,7.405-7.405-.009-14.819" fill="none" stroke={"#000000"} strokeWidth="1"/>
                                  </g>
                                  <g data-name="Group 722" transform="translate(0 -7.763)">
                                    <path data-name="Path 262" d="M0,0H14.2" fill="none" stroke={"#000000"}  strokeWidth="1"/>
                                  </g>
                                </g>
                              </svg>
                              </span></h3></a>
                        <p className={"anim0 reset1 row1-1b arBox" + ((recentWorkIntro.text && props.fonts) ? " on" : "") }><span className="arBoxIn">{recentWorkIntro.text}</span></p>
                      
                    </div>
                  }
                  </div>
                  </div>
                <span id="row1triggerEnd" className="animTrigger1"></span>
                <span id="row3trigger" className="animTrigger1"></span>
                {recentWorkIntro && <div className="swiperColumn squareSlides">
                  <Swiper
                        modules={[Navigation,Pagination]}
                        init={true}
                        breakpoints={{
                          0: {
                            slidesPerView: 1.25,
                            spaceBetween: 0
                          },
                          820: {
                            slidesPerView: 1.25,
                            spaceBetween: 15
                          },
                          1100: {
                            slidesPerView: 2.25,
                            spaceBetween: 30
                          },
                          1600: {
                            slidesPerView: 2.5,
                            spaceBetween: 0
                          },
                          2400: {
                            slidesPerView: 2.25,
                            spaceBetween: 80
                          }
                        }}
                        className="swiper-slide"
                        mousewheel={{
                          forceToAxis: true
                        }}
                        loop
                        loopedSlides={5}
                        initialSlide={0}
                 
                        pagination={{ clickable: true, type: "fraction", el: "#swiperFraction1" }}
                        navigation={{nextEl: "#swiperNext1", prevEl: "#swiperPrev1"}}
                        >
                       {recentWorkIntro.recentworks.map((obj, i) => (
                          <SwiperSlide 
                            key={"work_" + i}
                          >
                            <Link to={obj.link && obj.link} 
                              className={"slide anim0 reset1"}
                              // id={"recentWorkSlide" + i} 
                              onClick={() => handleBackground(obj.color)}
                              >
                              <span className="slideInner"
                              style={{
                                backgroundColor: "#000000",
                                color: "#ffffff"
                              }}>
                                <img src={getMedia(obj.image, obj.image_m)} alt={obj.image_alt ? obj.image_alt : obj.heading} />
                                <div className="textRow" style={{backgroundColor: "#000000"}}>
                                  <h3>{obj.heading}</h3>
                                  {obj.subheading && <h4>{obj.subheading}</h4>}
                                  <p>{obj.text}</p>
                                  <div className="learn">
                                  <span>See more</span>
                                  <svg width="15.167" height="15.526" viewBox="0 0 15.167 15.526">
                           
                                  <g data-name="Group 723" transform="translate(0 15.526)">
                                    <g data-name="Group 721" transform="translate(7.055 -0.354)">
                                      <path data-name="Path 261" d="M0,0,7.405-7.405-.009-14.819" fill="none" stroke={"#ffffff"} strokeWidth="1"/>
                                    </g>
                                    <g data-name="Group 722" transform="translate(0 -7.763)">
                                      <path data-name="Path 262" d="M0,0H14.2" fill="none" stroke={"#ffffff"}  strokeWidth="1"/>
                                    </g>
                                  </g>
                                </svg>
                                  </div>
                                </div>
                        
                                </span>
                            </Link>
                            </SwiperSlide>
                          ))}
                         
                      </Swiper>
                      <div className="column">
                        <div className="swiperNav">
                       
                            <div className="swiperPrev" id="swiperPrev1">Prev</div>
                            <div className="swiperFraction" id="swiperFraction1" />
                            <div className="swiperNext" id="swiperNext1">Next</div>
                          </div>
                      </div> 
                    </div>}
                    <span id="row3triggerEnd" className="animTrigger1"></span>
                          
             
            </div>
  
          
          
        </div>
      </CSSTransition>
  );
}

export default Home;