import React, { useState, useEffect, Fragment } from "react";
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AspectRatioBox from "./aspectRatioUtil";
import { getStatic } from '../static';

import configData from "../config.json";

function getMedia(path) {
  return configData.API_URL + path;
}

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

function sizeOverlay(){
  let colorOverlays = document.getElementsByClassName('colorOverlay');
  for (var i = 0; i < colorOverlays.length; i++) {
    let w = colorOverlays[i].offsetWidth;
    let h = colorOverlays[i].offsetHeight;
    let o = colorOverlays[i].parentElement.getElementsByClassName('overlay')[0];
    o.style.width = w + "px";
    o.style.height = h + "px";
  }
}

function CaseIndex(props) {

    const [loaded, setLoaded] = useState(false);
    const [objs, setObjs] = useState();

    const getObjs = async() => {
      await axios({
        method: 'get',
        url: configData.API_URL + "/api/case-list/"
        })
        .then(response => {
          const dataJSON = response.data;
          setObjs(dataJSON);
        });
    }

    useEffect(() => {
      
      let rowTl;
      if (objs) {
        
        setLoaded(true);
        console.log('case studies loaded');    
        
       
        

        objs.forEach((o, i) => {

         if (props.isMobile) {
          let rowTl = gsap.timeline({
            defaults: {
              duration: 0.6, 
              ease: "power1",
            },
            scrollTrigger: {
              trigger: "#caseId_" + o.id,
              start: "top 60%",
              end: "+=100",
              // start: "center center",
              // end: "top top",
              scrub: 1,
              markers: false
            }
          });
         rowTl.fromTo("#caseId_" + o.id, {translateY: "40px"}, {translateY: "0"});
         rowTl.fromTo("#caseId_" + o.id, {opacity: 0}, {opacity: 1},"<");
         } else {
          let rowTl = gsap.timeline({
            defaults: {
              duration: 0.6, 
              ease: "power1",
            },
            scrollTrigger: {
              trigger: "#caseId_" + o.id,
              start: "top 80%",
              end: "+=100",
              scrub: 1,
              markers: false
            }
          });
         rowTl.fromTo("#caseId_" + o.id, {translateY: "40px"}, {translateY: "0"});
         rowTl.fromTo("#caseId_" + o.id, {opacity: 0}, {opacity: 1},"<");

         }
          
        });
        

      }
      return () => {
        
        rowTl && rowTl.kill();
      }
    }, [objs, props.isMobile])

    useEffect(() => {
      let rowTl0;
      if (loaded) {
        rowTl0 = gsap.timeline({
          defaults: {
            ease: "power1",
          },
        });
        rowTl0.fromTo("#row0 h1.anim0", {translateY: "40px"}, {translateY: "0"});
        rowTl0.fromTo("#row0 h1.anim0", {opacity: 0}, {opacity: 1},"<");
        rowTl0.fromTo("#row0 h3.anim0", {translateY: "40px"}, {translateY: "0"});
        rowTl0.fromTo("#row0 h3.anim0", {opacity: 0}, {opacity: 1},"<");
        rowTl0.fromTo(".gridCaseIndex.anim0", {translateY: "40px"}, {translateY: "0"});
        rowTl0.fromTo(".gridCaseIndex.anim0",  {opacity: 0}, {opacity: 1},"<");
      }
      return () => {
        rowTl0 && rowTl0.kill();
       
      }
    }, [loaded])


    useEffect(() => {
      gsap.to('#core', {backgroundColor: '#ffffff', duration: 0.3})
      props.menuColor('none');
      props.accentColor('#FFFFFF');
      props.logoColor('fillBlack');
      // Page Meta
      document.title = "Case Studies | " + configData.BASE_TITLE
      document.querySelector('meta[name="description"]').setAttribute("content", "");
      document.documentElement.scrollTop = 0;

      // Load Content
      getObjs();
  
    }, []);

    function arrowRight(objId, textColor) {
      return (
        <svg width="15.167" height="15.526" viewBox="0 0 15.167 15.526">
          <defs>
            <clipPath id={"clip-path_" + objId}>
              <path data-name="Path 263" d="M0,0H15.167V-15.526H0Z" fill="none"/>
            </clipPath>
          </defs>
          <g data-name="Group 723" transform="translate(0 15.526)" clipPath={"url(#clip-path_" + objId + ")"}>
            <g data-name="Group 721" transform="translate(7.055 -0.354)">
              <path data-name="Path 261" d="M0,0,7.405-7.405-.009-14.819" fill="none" stroke={(textColor)?"#ffffff":"#ffffff"} strokeWidth="1"/>
            </g>
            <g data-name="Group 722" transform="translate(0 -7.763)">
              <path data-name="Path 262" d="M0,0H14.2" fill="none" stroke={(textColor)?"#ffffff":"#ffffff"}  strokeWidth="1"/>
            </g>
          </g>
        </svg>
      )
    }


    function handleBackground(color) {
      gsap.to('#core', {backgroundColor: color, duration: 0.2})
    }
  


  return (
      <CSSTransition in={loaded} timeout={200} unmountOnExit classNames="pageItem">
         <div id="caseIndex">
          <div className="column">
          <span className="row" id="row0">
            <div className="heading landing">
              <AspectRatioBox tag={"h1"} classes={["anim0"]} fonts={props.fonts} content={"Case Studies"} />
              <AspectRatioBox tag={"h3"} ariaLevel={2} classes={["anim0"]} fonts={props.fonts} content={"A Diverse Range of Work."} />
              <AspectRatioBox tag={"h3"} classes={["alt", "anim0"]} fonts={props.fonts} content={"The work below represents the depth and breadth of UDXD’s capabilities. We tailor end-to-end design solutions from initial concept development to full technical implementation and support."} />
            </div>
          </span>
            <div className="grid gridCaseIndex anim0">
            {objs && objs.map((obj, i) => (
   
                <Link to={obj.get_absolute_url}
                data-block={"case" + (i + 1)}
                key={'case_' + i}
                id={'caseId_' + obj.id}
                style={{
                  backgroundColor: obj.grid_color?"#000000":"#1a1a1a",
                  // paddingTop: "100%"
                }}
                onClick={() => handleBackground(obj.background_color)}
                className={"item arBox caseIndexBox" + (([2,3,8,9,14,15].includes(i))?" shortBox":"")}
                >
                <span className={"animTrigger" + (([3,9,15].includes(i))?" shortBoxOdd":"")} id={'triggerCaseId_' + obj.id}></span>
                <span className="arBoxIn">
                  <span className="caseIndexBoxIn">
                    <img src={getMedia(obj.thumbnail)} alt={obj.image_alt ? obj.image_alt : obj.name } />
                    <span className="textRow" 
                      style={{
                        color: (obj.grid_text_color)?"#ffffff":"#ffffff",
                        background: "rgba(0,0,0,1)"
                      }}
                      >
                      <h3>{obj.name}</h3>
                      <p>{obj.intro}</p>
                      <div className="learn">
                      <span>See more</span>
                      {arrowRight("_caseBox_" + i, obj.grid_text_color)}
                      </div>
                    </span>
                  </span>
                </span>
              </Link>
            
            ))}
            </div>
          </div>
        </div>
      </CSSTransition>
  );
}

export default CaseIndex;


