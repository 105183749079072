import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { getStatic } from '../static';
import SVG, { Props as SVGProps } from 'react-inlinesvg'
import SwiperCore, { Navigation, Pagination, Mousewheel, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";

import { ReactComponent as ArrowRight } from "../static/svg/arrow-right.svg";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/mousewheel';
import 'swiper/css/free-mode';

import configData from "../config.json";

function getMedia(path) {
  return configData.API_URL + path;
}

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

function sizeOverlay(){
  let colorOverlays = document.getElementsByClassName('colorOverlay');
  for (var i = 0; i < colorOverlays.length; i++) {
    let w = colorOverlays[i].offsetWidth;
    let h = colorOverlays[i].offsetHeight;
    let o = colorOverlays[i].parentElement.getElementsByClassName('overlay')[0];
    o.style.width = w + "px";
    o.style.height = h + "px";
  }
}

function Contact(props) {

    const [loaded, setLoaded] = useState(false);
    const [objs, setObjs] = useState();
    const [industries, setIndustries] = useState();
    const [recentWorkIntro, setRecentWorkIntro] = useState();
    const [values, setValues] = useState();
    const [clientIntro, setClientIntro] = useState();
    const [peopleIntro, setPeopleIntro] = useState();

    useEffect(() => {
      let rowTl0;
      if (loaded) {
        rowTl0 = gsap.timeline({
          defaults: {
            ease: "power1",
          },
        });
        rowTl0.fromTo("#contactPage h1.anim0", {translateY: "40px"}, {translateY: "0"});
        rowTl0.fromTo("#contactPage h1.anim0", {opacity: 0}, {opacity: 1},"<");
        rowTl0.fromTo(".grid3.anim0", {translateY: "40px"}, {translateY: "0"});
        rowTl0.fromTo(".grid3.anim0",  {opacity: 0}, {opacity: 1},"<");
      }
      return () => {
        rowTl0 && rowTl0.kill();
       
      }
    }, [loaded])



    useEffect(() => {
      gsap.to('#core', {backgroundColor: '#ffffff', duration: 0.3})
      props.menuColor('none');
      props.accentColor('#FFFFFF');
      props.logoColor('fillBlack');
      // Page Meta
      document.title = "Contact | " + configData.BASE_TITLE
      document.querySelector('meta[name="description"]').setAttribute("content", "");
      document.documentElement.scrollTop = 0;

      // Load Content
      setLoaded(true)
  
    }, []);



  return (
      <CSSTransition in={loaded} timeout={200} unmountOnExit classNames="pageItem">
         <div id="contactPage">
          <div className="column">
            <h1 className="anim0">Reach Out</h1>
            <div className="grid grid3 anim0">
              <div className="item borderTop">
                <h3 className={"arBox" + ((props.fonts) ? " on" : "") } aria-level={2}><span className="arBoxIn" dangerouslySetInnerHTML={ {__html: (props.fonts ? "Contact":"")} } /></h3>
                <h3 className={"alt" + ((props.fonts) ? " on" : "") }><span className="">
                <a href="mailto:info@uniondesign.com" className="borderBottom" aria-label="contact us">info@udxd.co</a>
                <br/><br/>
                <a href="tel:+12126478500">212 647-8500</a>
                <br/><br/>
                <a href="https://www.google.com/maps/dir//union+design/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x89c259a28bce26d5:0x9585e4594975f0b9?sa=X&ved=2ahUKEwjtkbPq-Jr9AhXFEFkFHXjlA58Q9Rd6BAhhEAU" rel="noreferrer" target="_blank">UDXD<br/>275 Grand Street<br/>
                Floor 4<br/>
                New York, NY 10002</a>
                </span>
                </h3>
              </div>
              <div className="item borderTop">
                <h3 className={"arBox" + ((props.fonts) ? " on" : "") } aria-level={2}><span className="arBoxIn" dangerouslySetInnerHTML={ {__html: (props.fonts ? "Jobs & Internships":"")} } /></h3>
                <h3 className={"alt" + ((props.fonts) ? " on" : "") }><span className="">
                We're always looking to expand our team of talented, dynamic, and dedicated creatives. Let's talk!<br/><br/>
                <a href="mailto:jobs@uniondesign.com" className="borderBottom">jobs@udxd.co</a>
                </span>
                </h3>
              </div>
              <div className="item borderTop">
                <h3 className={"arBox" + ((props.fonts) ? " on" : "") } aria-level={2}><span className="arBoxIn" dangerouslySetInnerHTML={ {__html: (props.fonts ? "Project Inquiries":"")} } /></h3>
                <h3 className={"alt"}>
                Please share project and media inquiries.<br/><br/>
              <a href="mailto:info@uniondesign.com" className="borderBottom" aria-label="project inquiries">info@udxd.co</a> 
              </h3>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
  );
}

export default Contact;