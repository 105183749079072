import axios from 'axios';
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import Cookies from 'js-cookie';
import AspectRatioBox from "./aspectRatioUtil";
import { ReactComponent as Filter } from "../static/svg/filter.svg";
import configData from "../config.json";

function getMedia(path) {
  return configData.API_URL + "/media/" + path;
}

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

function sizeOverlay(){
  let colorOverlays = document.getElementsByClassName('colorOverlay');
  for (var i = 0; i < colorOverlays.length; i++) {
    let w = colorOverlays[i].offsetWidth;
    let h = colorOverlays[i].offsetHeight;
    let o = colorOverlays[i].parentElement.getElementsByClassName('overlay')[0];
    o.style.width = w + "px";
    o.style.height = h + "px";
  }
}

function WorkIndex(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    let [parent, setParent] = React.useState(
      searchParams.get("parent")
    );
    let [type, setType] = React.useState(
      searchParams.get("type")
    );
    const [loaded, setLoaded] = useState(false);
    const [objs, setObjs] = useState();
    const [next, setNext] = useState();
    const [prev, setPrev] = useState();
    const [filters, setFilters] = useState();
    const [apiUrl, setApiUrl] = useState();
    const [results, setResults] = useState();
    const [filter, setFilter] = useState();
    const [filterOpen, setFilterOpen] = useState(false);
    const [intro, setIntro] = useState();

    const getObj = async(pageUrl) => {
      let headers = {}
      if (props.username) {
        headers = {
          Authorization: 'Token ' + Cookies.get('_udxdworkid')
        }
        console.log('headers', headers)
      } 
      await axios({
        method: 'get',
        url: pageUrl,
        headers: headers
        })
        .then(response => {
          const dataJSON = response.data;
          setObjs(dataJSON.results);
          setNext(dataJSON.next);
          setPrev(dataJSON.previous);
          console.log('objs', dataJSON.results)

        });
    }

    const getSearchObj = async(pageUrl) => {
    
      await axios({
        method: 'get',
        url: pageUrl
        })
        .then(response => {
          const dataJSON = response.data;
          if (dataJSON) {
            setResults(dataJSON)
          } else {
            setResults(["No results"])
          } 
          
          console.log('results', pageUrl, dataJSON)
        });
    }

    const getFilters = async() => {
      await axios({
        method: 'get',
        url: configData.API_URL + "/api/project-type/"
        })
        .then(response => {
          const dataJSON = response.data;
          console.log('filters', dataJSON)
          setFilters(dataJSON);
        });
    }

    function hideFilters(){
      console.log('hide')
      gsap.to("#filterCols", {opacity: 0, height: 0,  duration: 0.2})
      gsap.to('#workGrid', {css: {marginTop: 40}, duration: 0.2}) 
      setFilterOpen(false);
    }

    function query(slug, name, isParent) {
      setObjs(null);
      let query = "";
      let param = "project_type="
      if (isParent) {
        param = "project_type_parent="
      }
      if (apiUrl.indexOf('?page') > -1) {
        query = "&" + param + slug;
      } else {
        query = "?" + param + slug;
      }
      getObj(apiUrl + query)
      setFilter(name);
      setSearchParams({});
      
      hideFilters();
      
    }

    function showFilters(filter) {

      if (!filterOpen) {
        setSearchParams({});
        function finishFilters() {
          gsap.to('#filterCols', {opacity: 1, height: "auto", duration: 0.2})
          gsap.to('#workGrid', {css: {marginTop: 480}, duration: 0.2})
        }
        finishFilters();
   
        setFilterOpen(true);
      } else {

        hideFilters();
      }
      
     
    }

    function search(e) {
      let url = configData.API_URL + "/api/work-search/"
      let val = e.target.value;
      if (val.length >= 3) {
        let query = "?q=" + val;
        getSearchObj(url + query)
      } else {
        setResults()
      }



    }

    useEffect(() => {
      
      let rowTl;
      if (objs && filters) {
       
        setLoaded(true);
        console.log('case studies loaded');    

       
  
      
      }
      return () => {
        
        rowTl && rowTl.kill();
      }
    }, [objs, filters, props.isMobile])

    useEffect(() => {
      let rowTl0;
      if (loaded) {
        gsap.to("#workGrid", {opacity: 1, duration: 0.2, delay: 1})
        rowTl0 = gsap.timeline({
          defaults: {
            ease: "power1",
          },
        });
        rowTl0.fromTo("#row0 h1.anim0", {translateY: "40px"}, {translateY: "0"});
        rowTl0.fromTo("#row0 h1.anim0", {opacity: 0}, {opacity: 1},"<");
        rowTl0.fromTo("#row0 h3.anim0", {translateY: "40px"}, {translateY: "0"});
        rowTl0.fromTo("#row0 h3.anim0", {opacity: 0}, {opacity: 1},"<");
        rowTl0.fromTo("#filterRow", {opacity: 0}, {opacity: 1},"<");
      }
      return () => {
        rowTl0 && rowTl0.kill();
       
      }
    }, [loaded])

    useEffect(() => {
      if ((parent || type) && apiUrl && searchParams && filters) {
        let query = "";
        if (parent) {
          let param = "project_type_parent="
          query = "?" + param + parent;
          let obj = filters.find(obj => obj.slug === parent)
          setFilter(obj.name);
        }
        if (type) {
          let param = "project_type="
          query = "?" + param + type;
          let name;
          filters.forEach(obj => {
            obj.types.forEach(obj1 => {
              if( obj1.slug === type) {
                name = obj1.name
              }
            })
          })
          let obj = filters.find(obj => obj.slug === type);
          if (name) {
            setFilter(name);
          }
         
        }
 
        
        getObj(apiUrl + query);
      } else {
        if (apiUrl) {
          getObj(apiUrl);
          console.log("query", type, parent, apiUrl)
        }
        
      }

     
    }, [apiUrl, parent, type, searchParams, filters, props.username])


    useEffect(() => {
      
      gsap.to('#core', {backgroundColor: '#ffffff', duration: 0.3})
      props.menuColor('none');
      props.accentColor('#FFFFFF');
      props.logoColor('fillBlack');
      // Page Meta
      document.title = "Work Index | " + configData.BASE_TITLE
      document.querySelector('meta[name="description"]').setAttribute("content", "");
      document.documentElement.scrollTop = 0;

      // Load Content
     
      
      getFilters();
      
      var cookie = Cookies.get('_udxdworkid');
      axios({
        method: 'get',
        baseURL: configData.API_URL,
        url: '/api/portfolio/',
        headers: {
            Authorization: 'Token ' + cookie
        }
        })
        .then(response => {
          console.log('response', response, response.data);
          
          if (response.data['is_staff'] === true) {
            props.setIsStaff(true)
          } else {
            setIntro(response.data)

            console.log('intro', response.data)
          }

          let url = configData.API_URL + "/api/work/"
          setApiUrl(url)
    
          
        })
        .catch(function (error) {
          if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          } else if (error.request) {
          console.log(error.request);
          } else {
          console.log('Error', error.message);
          }
          console.log(error.config);
          props.setIsStaff(false);
     
      });

      console.log('search', parent)

    }, []);

    function arrow(objId, textColor, dir) {
      return (
        <svg className={dir === "left" ? "arrowLeft":"arrowRight"} width="15.167" height="15.526" viewBox="0 0 15.167 15.526">
          <defs>
            <clipPath id={"clip-path_" + objId}>
              <path data-name="Path 263" d="M0,0H15.167V-15.526H0Z" fill="none"/>
            </clipPath>
          </defs>
          <g data-name="Group 723" transform="translate(0 15.526)" clipPath={"url(#clip-path_" + objId + ")"}>
            <g data-name="Group 721" transform="translate(7.055 -0.354)">
              <path data-name="Path 261" d="M0,0,7.405-7.405-.009-14.819" fill="none" stroke={textColor} strokeWidth="1"/>
            </g>
            <g data-name="Group 722" transform="translate(0 -7.763)">
              <path data-name="Path 262" d="M0,0H14.2" fill="none" stroke={textColor}  strokeWidth="1"/>
            </g>
          </g>
        </svg>
      )
    }


    function handleBackground(color) {
      gsap.to('#core', {backgroundColor: color, duration: 0.2})
    }
  


  return (
      <CSSTransition in={loaded} timeout={200} unmountOnExit classNames="pageItem">
         <div id="workIndex">
          <div className="column">
          {props.isStaff && <span className="row" id="row0">
            <div className="heading landing">
              <AspectRatioBox tag={"h1"} classes={["anim0"]} fonts={props.fonts} content={"Work"} />
            </div>
            <span id="filterRow" className="filterRow anim0">
            <span className="filters">
              <span className="search">
                <input type="text" placeholder="Search" onKeyUp={(e) => search(e) } />
               
                {results && (results.length > 0) && <span className="results">
                   {results.map((obj, i) => (
                    <a href={obj.get_absolute_url} key={"result_" + i}>{obj.title}</a>
                  ))}
                </span>}
                {results && results.length === 0 && <span className="results">
                   <h3>No results found</h3>
                </span>}
              </span>
              <div className="filterBox">
                <span className={"filterOn ui" + (filterOpen ? " active":"")} id="filterOn" onClick={() => showFilters()}><Filter /> <h3>{filter ? filter : "Filters"}</h3></span>
                <span className={"filterCols" + (filterOpen ? " active":"")} id="filterCols">
         
                  {filters && filters.map((obj, i) => (
                    <span className="filterCol" key={'filter_'+ i}>
                      <h4 className="ui" onClick={() => query(obj.slug, obj.name, true)}>{obj.name}</h4>
                      {obj.types && obj.types.map((obj1, j) => (
                        <span key={'filter1_' + j} onClick={() => query(obj1.slug, obj1.name)} className="subFilter ui">
                          {obj1.name}
                        </span>
                      ))}
                    </span>
                  ))}
                </span>
              </div>
              
            </span>
          </span>
          </span>}

          {intro && <span className='row' id="row0">
          <div className="heading landing">
          <AspectRatioBox tag={"h1"} classes={["anim0"]} fonts={props.fonts} content={intro.title} />
          <h3 className='anim0 alt'>{intro.intro}</h3>
        </div>  
            </span>}

            <div className={"grid gridWorkIndex"} id="workGrid">
            {objs && objs.map((obj, i) => (
   
                <Link to={obj.get_absolute_url}
                data-block={"case" + (i + 1)}
                key={'work_' + i}
                id={'workId' + obj.id}
                // style={{
                //   backgroundColor: "#000000"
                // }}
                onClick={() => handleBackground(obj.background_color)}
                className={"item arBox workIndexBox on"}
                >
                <span className={"animTrigger"} id={'triggerCaseId_' + obj.id}></span>
             
                  <span className="caseIndexBoxIn">
                    <img src={getMedia(obj.thumbnail)} alt={obj.image_alt ? obj.image_alt : obj.name } />
                    <span className="textRow">
                      <h3>{obj.title}</h3>
                      <p>{obj.project_type_parent} / {obj.project_type}</p>
                     
                    </span>
                  </span>
             
              </Link>
            
            ))}
            </div>
            <div className="pagination">
            {prev && <span className="pageBtn ui" onClick={() => getObj(prev)}>{arrow("_next", "#000000", 'left')} Previous</span>}
              {next && <span className="pageBtn ui" onClick={() => getObj(next)}>Next  {arrow("_next", "#000000", 'right')}</span>}
            </div>
          </div>
        </div>
      </CSSTransition>
  );
}

export default WorkIndex;