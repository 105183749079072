import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AspectRatioBox from "./aspectRatioUtil";
// import { ReactComponent as ArrowRight } from "../static/svg/arrow-right.svg";
import Loader from '../static/svg/loader.svg';
import { Navigation, Pagination, Mousewheel, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/mousewheel';
import 'swiper/css/free-mode';

import configData from "../config.json";

function getMedia(path) {
  return configData.API_URL + path;
}

gsap.registerPlugin(ScrollTrigger);

function About(props) {

    const [loaded, setLoaded] = useState(false);
    const [values, setValues] = useState();
    const [clientIntro, setClientIntro] = useState();
    const [peopleIntro, setPeopleIntro] = useState();

    const getOurClients = async() => {
    
        !clientIntro && await axios({
         method: 'get',
         url: configData.API_URL + "/api/our-clients/"
         })
         .then(response => {
           const dataJSON = response.data;
           setClientIntro(dataJSON);
           // console.log('our clients set');
         });
       }

    const getValue = async() => {
      !values && await axios({
        method: 'get',
        url: configData.API_URL + "/api/value/"
        })
        .then(response => {
          const dataJSON = response.data;
          setValues(dataJSON);
          // console.log('values set');
        });
    }


    const getOurPeople = async() => {
      !peopleIntro && await axios({
      method: 'get',
      url: configData.API_URL + "/api/our-people/"
      })
      .then(response => {
        const dataJSON = response.data;
        setPeopleIntro(dataJSON);
        // console.log('our people set');
      });
        
    }   

    useEffect(() => {
      let rowTl;
      if (peopleIntro) {
        ScrollTrigger.refresh();
        setTimeout(() => {
          rowTl = gsap.timeline({
            defaults: {
              duration: 0.4, 
              ease: "power1",
            },
            scrollTrigger: {
              trigger: "#row3trigger",
              start: "center 80%",
              end: "top top",
              scrub: 1,
              markers: false
            }
          });

         rowTl.fromTo(".anim0.row3", {translateY: "40px"}, {translateY: "0"});
         rowTl.fromTo(".anim0.row3", {opacity: 0}, {opacity: 1},"<");

        }, 300);
      

      }
      return () => {
        rowTl && rowTl.kill();
      }
    }, [peopleIntro])


    useEffect(() => {
      let rowTl;
      if (values) {
        ScrollTrigger.refresh();
        setTimeout(() => {
          rowTl = gsap.timeline({
            defaults: {
              duration: 0.4, 
              ease: "power1",
            },
            scrollTrigger: {
              trigger: "#row2trigger",
              start: "center 80%",
              end: "top top",
              scrub: 1,
              markers: false,
              onEnter: getOurPeople
            }
          });

         rowTl.fromTo(".anim0.row2", {translateY: "40px"}, {translateY: "0"});
         rowTl.fromTo(".anim0.row2", {opacity: 0}, {opacity: 1},"<");
         rowTl.fromTo(".anim0.row2-1a", {translateY: "40px"}, {translateY: "0"});
         rowTl.fromTo(".anim0.row2-1a", {opacity: 0}, {opacity: 1},"<");
         rowTl.fromTo(".anim0.row2-1b", {translateY: "40px"}, {translateY: "0"});
         rowTl.fromTo(".anim0.row2-1b", {opacity: 0}, {opacity: 1},"<");

        }, 300);
      

      }
      return () => {
        rowTl && rowTl.kill();
      }
    }, [values])


    useEffect(() => {
      let rowTl;
      if (clientIntro) {
        getValue();


        rowTl = gsap.timeline({
          defaults: {
            ease: "power1",
          },
        });
        rowTl.fromTo(".anim0.row4a", {translateY: "40px"}, {translateY: "0"});
        rowTl.fromTo(".anim0.row4a", {opacity: 0}, {opacity: 1},"<");
        rowTl.fromTo(".anim0.row4b", {translateY: "40px"}, {translateY: "0"});
        rowTl.fromTo(".anim0.row4b", {opacity: 0}, {opacity: 1},"<");
        rowTl.fromTo(".anim0.row4c0", {translateY: "40px"}, {translateY: "0"});
        rowTl.fromTo(".anim0.row4c0", {opacity: 0}, {opacity: 1},"<");

      }
      return () => {
        rowTl && rowTl.kill();
      }
    }, [clientIntro])


    useEffect(() => {
      gsap.to('#core', {backgroundColor: '#ffffff', duration: 0.3})
      props.accentColor('#FFFFFF');
      props.logoColor('default');
      setLoaded(true);
      // Page Meta
      document.title = "About UDXD | " + configData.BASE_TITLE
      document.querySelector('meta[name="description"]').setAttribute("content", "");
      document.documentElement.scrollTop = 0;

    //    Anim Sequences
    
        getOurClients();
      
      console.log('about')
    }, []);


  function bioLoop(array, min, max) {
    let arrayFilter;
    if (!max) {
      arrayFilter = array.filter((obj0, i) => i < min);
    }
    if (!min) {
      arrayFilter = array.filter((obj0, i) => i > max);
    }
    if (min && max) {
      arrayFilter = array.filter((obj0, i) => (i > min) && (i < max));
    }
    return arrayFilter.map((obj, i) => (
      (obj.link ? <a href={obj.link} key={'people_' + i} target="blank" className="item translateY">
     <h4>{obj.name}</h4>
      <span className="bioLink">
        <p>{obj.title}</p> 
        <svg width="15.167" height="15.526" viewBox="0 0 15.167 15.526">
          <defs>
            <clipPath id={"clip-path_" + i}>
              <path data-name="Path 263" d="M0,0H15.167V-15.526H0Z" fill="none"/>
            </clipPath>
          </defs>
          <g data-name="Group 723" transform="translate(0 15.526)" clipPath={"url(#clip-path_" + i + ")"}>
            <g data-name="Group 721" transform="translate(7.055 -0.354)">
              <path data-name="Path 261" d="M0,0,7.405-7.405-.009-14.819" fill="none" stroke={(obj.text_color)?obj.text_color:"#000000"} strokeWidth="1"/>
            </g>
            <g data-name="Group 722" transform="translate(0 -7.763)">
              <path data-name="Path 262" d="M0,0H14.2" fill="none" stroke={(obj.text_color)?obj.text_color:"#000000"}  strokeWidth="1"/>
            </g>
          </g>
        </svg>  
      </span>
      </a> : <div key={'people_' + i} className="item">
        <h4>{obj.name}</h4>
        <p>{obj.title}</p>
      </div>)
    ))
  }

  return (
      <CSSTransition in={loaded} timeout={0} unmountOnExit classNames="pageItem">
    
         <div id="about">

          <div className="row" id="row0">
          <span id="row1trigger" className="animTrigger1"></span>
          <div className="column">

            <div>
              {clientIntro && 
                  <span>
                    <div className="anim0 row4a heading short">
              
                     <AspectRatioBox tag={"h1"} fonts={props.fonts} content={clientIntro.heading} />
                    </div>
                    <span className="full">
                      <span className="anim0 row4b">
                        <div className="heading">
                          <AspectRatioBox tag={"h3"} ariaLevel={2} classes={["short"]} fonts={props.fonts} content={clientIntro.subheading} />
                          <AspectRatioBox tag={"h3"} classes={["alt"]} fonts={props.fonts} content={clientIntro.text} />

                        </div>
                        <div>
                        <div id="logoContainer" className={"logoContainer arBox" + ((clientIntro.clients)?" on":"")}>
                          <div className="arBoxIn">
                          <span className="anim0 row4c0 grid grid6 logos">
                          {clientIntro && clientIntro.clients.filter((obj0, h) => h < 60).map((obj, i) => (
                            <div key={'clients_' + i} className="item">
                              <img src={getMedia(obj.image)} alt={obj.image_alt} />
                            </div>
                          ))}
                          </span>
                         
                      
                        </div>
                        </div>

                        </div>
                      </span>
            
                    </span>
                  </span>
              }
             
            </div>
          </div>
        </div>


           
            <div className="row">
              <span id="row2trigger" className="animTrigger1"></span>
              <div className="column">
                <div className="heading anim0 row2 short">
                <h2 className={"arBox" + ((props.fonts)?" on":"")}>
                <span className="arBoxIn">{(props.fonts ? ("Our Values"):"")}</span>
              </h2>
                
                </div>
                <div className="grid grid3">
                  {values && values.map((obj, i) => (
                    <div key={'values_' + i} className={"item borderTop anim0"  + ((i < 3)?" row2-1a":" row2-1b")}>
             
                        <h3 className={"arBox" + ((props.fonts)?" on":"")}>
                          <span className="arBoxIn">{(obj.heading && props.fonts ? ((props.isMobile?obj.heading.replace('<br>', ''):obj.heading)):"")}</span>
                        </h3>
                        <p>{obj.text}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            
          
            <div className="row bioRow">
            
              <div className="column">
  
                <div>
                <span id="row3trigger" className="animTrigger1"></span>
                  {peopleIntro && 
                    <div className="heading">
                        <h2 className="anim0 row3">{peopleIntro.heading}</h2>
                        {peopleIntro.peopleitems && peopleIntro.peopleitems.map((obj, i) => (
                          <div key={'peopleItem_' + i} className="item anim0 row3">
                          <div 
                            className="arBox"
                            style={{
                              paddingTop: "calc(1/" + ((props.isMobile)?parseFloat(obj.aspect_ratio_m?obj.aspect_ratio_m:0):parseFloat(obj.aspect_ratio)) + " * " + 100 + "%)"
                            }}
                            ><div 
                          className="arBoxIn"
                          >
                          {props.isMobile?<img src={props.isMobile?getMedia(obj.file_m):getMedia(obj.file)} alt={obj.file_alt} />:<video preload="auto" poster={obj.file_m} playsInline muted autoPlay loop src={getMedia(obj.file, null)} ><source src={getMedia(obj.file, obj.file_m)} preload={"none"} type="video/mp4"/></video>}
                            
                            </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  }
                  <div className="grid grid2 row3 anim0">
                  <div className="flex">
        
                    {peopleIntro && peopleIntro.bios && bioLoop(peopleIntro.bios, 6, null) }
                  </div>
                  <div className="flex">
                  {peopleIntro && peopleIntro.bios && bioLoop(peopleIntro.bios, 5, 11) }
                  </div>
                  <div className="flex">
                  {peopleIntro && peopleIntro.bios && bioLoop(peopleIntro.bios, null, 10) }
                  </div>
                  </div>

                </div>
              </div>
            </div>
          
          
        </div>
      </CSSTransition>
  );
}

export default About;