import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { getStatic } from '../static';
import SVG, { Props as SVGProps } from 'react-inlinesvg'
import SwiperCore, { Navigation, Pagination, Mousewheel, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";

import { ReactComponent as ArrowRight } from "../static/svg/arrow-right.svg";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/mousewheel';
import 'swiper/css/free-mode';

import configData from "../config.json";

function getMedia(path) {
  return configData.API_URL + path;
}

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

function Capabilities(props) {

    let {capabilitySlug} = useParams();

    const [loaded, setLoaded] = useState(false);
    const [capabilities, setCapabilities] = useState();
    const [capability, setCapability] = useState();
    const [recentWorkIntro, setRecentWorkIntro] = useState();

    const getCapabilities = async() => {
      await axios({
        method: 'get',
        url: configData.API_URL + "/api/capability-list/"
        })
        .then(response => {
          const dataJSON = response.data;
          setCapabilities(dataJSON);
        });
    }

    const getCapability = async() => {
        setCapability(null);
        await axios({
          method: 'get',
          url: configData.API_URL + "/api/capability/" + capabilitySlug
          })
          .then(response => {
            const dataJSON = response.data;
            setCapability(dataJSON);
            console.log('capability', dataJSON)
          });
      }


    useEffect(() => {
      
      
    }, [capabilities])

    useEffect(() => {
      let rowTl0;
      let rowTl;
      let rowTl2;
      let rowTl3;
        if (capability) {
            props.accentColor('#FFFFFF');
            setLoaded(true)

          rowTl0 = gsap.timeline({
            defaults: {
              ease: "power1",
            },
          });
          rowTl0.fromTo("#row0 h1", {translateY: "40px"}, {translateY: "0"});
          rowTl0.fromTo("#row0 h1", {opacity: 0}, {opacity: 1},"<");
          rowTl0.fromTo("#row0 h3", {translateY: "40px"}, {translateY: "0"});
          rowTl0.fromTo("#row0 h3", {opacity: 0}, {opacity: 1},"<");
          rowTl0.fromTo("#row1 .deliverableRow", {translateY: "40px"}, {translateY: "0"});
          rowTl0.fromTo("#row1 .deliverableRow",  {opacity: 0}, {opacity: 1},"<");

          if (capability.servicerows) {

            rowTl = gsap.timeline({
              defaults: {
                duration: 0.6, 
                ease: "power1",
              },
              scrollTrigger: {
                trigger: "#row2trigger",
                start: "center 80%",
                end: "top top",
                scrub: 1,
                markers: false
              }
            });
            let gridObjs = document.querySelectorAll('#row2 .anim0');
            //  console.log('gridObjs', gridObjs)
            gridObjs.forEach((obj, i) => {
              rowTl.fromTo(obj, {translateY: "40px"}, {translateY: "0"}, "<");
              rowTl.fromTo(obj, {opacity: 0}, {opacity: 1}, "<");
              
            });
          }
          if (capability.file) {
     
           rowTl2 = gsap.timeline({
             defaults: {
               duration: 0.6, 
               ease: "power1",
             },
             scrollTrigger: {
               trigger: "#row2bTrigger",
               start: "center 80%",
               end: "top top",
               scrub: 1,
               markers: false
             }
           });
           let gridObjs = document.querySelectorAll('#row2b .anim0');
           //  console.log('gridObjs', gridObjs)
           gridObjs.forEach((obj, i) => {
            rowTl2.fromTo(obj, {translateY: "40px"}, {translateY: "0"}, "<");
            rowTl2.fromTo(obj, {opacity: 0}, {opacity: 1}, "<");
             
           });
         }

         if (capability.cases) {

         rowTl3 = gsap.timeline({
           defaults: {
             duration: 0.6, 
             ease: "power1",
           },
           scrollTrigger: {
             trigger: "#row3trigger",
             start: "center 80%",
             end: "top center",
             scrub: 1,
             markers: false
           }
         });
         let gridObjs = document.querySelectorAll('#row3 .anim0');
         //  console.log('gridObjs', gridObjs)
         gridObjs.forEach((obj, i) => {
          rowTl3.fromTo(obj, {translateY: "40px"}, {translateY: "0"}, "<");
          rowTl3.fromTo(obj, {opacity: 0}, {opacity: 1}, "<");
           
         });
       }
          
  
        }
        return () => {
          rowTl0 && rowTl0.kill();
          rowTl && rowTl.kill();
          rowTl2 && rowTl2.kill();
          rowTl3 && rowTl3.kill();
        }
        
    }, [capability])

    useEffect(() => {
        capabilitySlug && getCapability();
    }, [capabilitySlug])

    useEffect(() => {
      gsap.to('#core', {backgroundColor: '#ffffff', duration: 0.3})
      setLoaded(true);
      props.logoColor('fillBlack');
      props.accentColor(null);
      // Page Meta
      document.title = "Capabilities | " + configData.BASE_TITLE
      document.querySelector('meta[name="description"]').setAttribute("content", "");
      document.documentElement.scrollTop = 0;

      // Load Content
      getCapabilities();

     
      

      console.log('capabilitySlug', capabilitySlug);

    }, []);

    function handleBackground(color) {
      gsap.to('#core', {backgroundColor: color, duration: 0.2})
    }



  return (
      <CSSTransition in={loaded} timeout={200} unmountOnExit classNames="pageItem">
         <div id="content">
      
            {capability && <div className="row row0" id="row0">
                <div className="column">
                    <div className="heading row0"><h1><span dangerouslySetInnerHTML={ {__html: capability.heading.replace('<br/>', '')} } /></h1></div>
                        <div className="full">
                            <span>
                                <div className="heading">
                                    <h3 aria-level="2">{capability.subheading}</h3>
                                    <h3 className="alt">{capability.text}</h3>
                                </div>
                            </span>
                            <span>
                            <ul>
                            {capability && capability.serviceitems && capability.serviceitems.map((obj, i) => (
                                <li key={'item_' + i}><span>{obj.heading}</span></li>
                            ))}
                            </ul>
                            </span>
                    </div>

                </div>
                
            </div>}

            {capability && capability.deliverables && (capability.deliverables.length > 0) && <div id="row1" className="full">
              <span id="row1trigger" className="animTrigger"></span>
              <div className="column deliverableRow">              
                <div className="grid grid3">
                 {capability.deliverables.map((obj, i) => (
                  <div key={"deliverable_" + i}>
                    <div className={"deliverableImage deliverableImage" + i}>
                      <img src={getMedia(obj.file)} alt="" />
                    </div>
                     {obj.heading && <h3 className={"row1" + ((props.fonts)?" on":"")}>
                        <span className="">{obj.heading}</span>
                      </h3>}
                      <p>{obj.text}</p>
                  </div>
                  
                  ))}
                  </div>
              </div>
              <span id="row1triggerEnd" className="animTrigger1"></span>
            </div>}
       
            {capability && capability.servicerows && (capability.servicerows.length > 0) && <div className="row" id="row2">
            <span id="row2trigger" className="animTrigger1"></span>
            <div className="column deliverableRow anim0">              
            
              {capability.servicerows.map((obj0, i) => (
                <div key={"servicerows_" + i}>
                  <div className="heading short">
                    <h2 className={"row1" + ((props.fonts)?" on":"")}>
                      <span className="">{obj0.heading}</span>
                    </h2>
                  </div>
                  <div className="grid grid_30_70">
                    {obj0.servicerowitems.map((obj, i) => (
                      <div key={"servicerowitem_" + i}>
                      {obj.text && <h3 className={"alt"}>{obj.text}</h3>}
                      {obj.file && <div className={"serviceRowImage"}>
                          <img src={props.isMobile?getMedia(obj.file_m):getMedia(obj.file)} alt="" />
                        </div>}

                      </div>
                      
                      ))}
                      </div>
                  
                </div>
                
                ))}
              
            </div>
   
                  
          </div>}

               
      
          {capability && !props.isMobile && <div className="row" id="row2b">
          <span id="row2bTrigger" className="animTrigger"></span>
              <div className="column anim0">
             
                  {capability.file && <video width={"100%"} height={"auto"} muted playsInline autoPlay loop>
                      <source src={getMedia(capability.file)} title={capability.file_alt} type="video/mp4" />
                      Your browser does not support the video tag.
                  </video>}
              </div>
              
          </div>}
         
         {capability && capability.cases && <div className="row" id="row3">
         <span id="row3trigger" className="animTrigger"></span>
          <div className="column anim0">              
            <div>
            {capability.cases_heading && 
              <div className="heading short">
                  <h2 className={"row1 arBox" + ((props.fonts)?" on":"")}>
                    <span className="arBoxIn">{(capability.cases_heading && props.fonts ? (capability.cases_heading):"")}</span>
                  </h2>
                  {capability.cases_subheading && <h3 className="row1-1a">{capability.cases_subheading}</h3>}
                  
         
              </div>
            }
            </div>
          </div>
            <div className="swiperColumn squareSlides anim0">
                <Swiper
                  modules={[Navigation,Pagination, Mousewheel, FreeMode]}
                  init={false}
                  breakpoints={{
                    0: {
                      slidesPerView: 1.25,
                      spaceBetween: 0
                    },
                    820: {
                      slidesPerView: 1.25,
                      spaceBetween: 15
                    },
                    1100: {
                      slidesPerView: 2.5,
                      spaceBetween: 30
                    },
                    1600: {
                      slidesPerView: 2.5,
                      spaceBetween: 0
                    },
                    2400: {
                      slidesPerView: 2.5,
                      spaceBetween: 0
                    }
                  }}
                  className="swiper-slide"
                  freeMode={{
                    enabled: true,
                    sticky: true
                  }}
                  mousewheel={{
                    forceToAxis: true
                  }}
                  
                  pagination={{ clickable: true, type: "fraction", el: "#swiperFraction1" }}
                  navigation={{nextEl: "#swiperNext1", prevEl: "#swiperPrev1"}}
                  // onSwiper={(swiper) => setEngageSwiper(swiper)}
                  >
                  {capability && capability.cases && capability.cases.map((obj, i) => (
                    <SwiperSlide 
                      key={"work_" + i}
                      onClick={() => handleBackground(obj.background_color)}
                    >
                      <Link to={obj.get_absolute_url} className={"slide"}>
                        <span className="slideInner" id={"recentWorkSlide" + i} style={{
                          backgroundColor: "#000000",
                          color: "#FFFFFF"
                        }}>
                          <img src={getMedia(obj.thumbnail)} alt={obj.image_alt ? obj.image_alt : obj.heading} />
                          <div className="textRow" style={{backgroundColor: "#000000"}}>
                            <h3>{obj.subheading}</h3>
                            {obj.intro && <p dangerouslySetInnerHTML={ {__html: (props.fonts ? (obj.intro):"")} } />}
                            <div className="learn">
                            <span>See more</span>
                            <svg width="15.167" height="15.526" viewBox="0 0 15.167 15.526">
                            <g data-name="Group 723" transform="translate(0 15.526)">
                              <g data-name="Group 721" transform="translate(7.055 -0.354)">
                                <path data-name="Path 261" d="M0,0,7.405-7.405-.009-14.819" fill="none" stroke={"#ffffff"} strokeWidth="1"/>
                              </g>
                              <g data-name="Group 722" transform="translate(0 -7.763)">
                                <path data-name="Path 262" d="M0,0H14.2" fill="none" stroke={"#ffffff"}  strokeWidth="1"/>
                              </g>
                            </g>
                          </svg>
                            </div>
                          </div>
                  
                          </span>
                      </Link>
                      </SwiperSlide>
                    ))}
                        <SwiperSlide></SwiperSlide>
                </Swiper>  
             
                <div className="column">
                  <div className="swiperNav">
                  
                      <div className="swiperPrev" id="swiperPrev1">Prev</div>
                      <div className="swiperFraction" id="swiperFraction1" />
                      <div className="swiperNext" id="swiperNext1">Next</div>
                    </div>
                </div> 
              </div>
            
          </div>   }
             
            
            
                   
          
        </div>
      </CSSTransition>
  );
}

export default Capabilities;