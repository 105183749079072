import React, { useState, useEffect, useCallback, useRef} from "react";
import {
  BrowserRouter,
  Link,
  Routes,
  Route
} from "react-router-dom";
import { gsap } from "gsap";
import axios from 'axios';
import Cookies from 'js-cookie';
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

import './App.css';
import Home from './components/home';
import About from './components/about';
import Contact from './components/contact';
import Capabilities from './components/capabilities';
import Case from './components/case';
import CaseIndex from './components/caseIndex';
import Legal from './components/legal';
import Work from './components/work';
import WorkIndex from './components/workIndex';
import SignIn from './components/signin';

import { ReactComponent as Logo } from './static/svg/logo.svg';
import { ReactComponent as LinkedIn } from './static/svg/01-linkedin.svg';
import { ReactComponent as Instagram } from './static/svg/02-instagram.svg';
import { ReactComponent as Pinterest } from './static/svg/03-pinterest.svg';
import { ReactComponent as Behance } from './static/svg/04-behance.svg';

import configData from "./config.json";

gsap.registerPlugin(MorphSVGPlugin);


function App() {
  const [username, setUsername] = useState();
  const [isStaff, setIsStaff] = useState(false);
  const [menuOn, setMenuOn] = useState(false);
  const [navSm, setNavSm] = useState(false);
  const [menuClass, setMenuClass] = useState('blue');
  const [yPos, setYPos] = useState(0);
  const [footerClass, setFooterClass] = useState('auto');
  const [isMobile, setIsMobile] = useState(null);
  const [isTablet, setIsTablet] = useState(null);
  const [capabilities, setCapabilities] = useState();
  const [loaded, setLoaded] = useState(false);
  const [fontsReady, setFontsReady] = useState(false);
  const [logoColor, setLogoColor] = useState(null);
  const [accentColor, setAccentColor] = useState(null);

  const menuOpenRef = useRef();
  const menuIdle = useRef();
  const buttonColorRef = useRef();

  const menuComplete = useCallback((runAnim) => {
    setTimeout(() => {
     
      menuIdle.current = true;
      if (runAnim) {
        if (isMobile === false) {
          gsap.to("#menuWrap", {height: "160px", duration: 0});
          let menuBtn = document.getElementById('menuBtn');
          menuBtn.setAttribute('viewBox', "0 0 1920 160");
        } else {
          gsap.to("#menuWrap", {height: "100px", duration: 0});
          let menuBtn_m = document.getElementById('menuBtn_m');
          menuBtn_m.setAttribute('viewBox', "0 0 600 160");
        }
        // console.log('menu complete');
        let corner = document.getElementById('cornerIn');
        corner.setAttribute('filter', "url(#dropShadow)");
      }
      
      // console.log('menuComplete', runAnim, menuIdle.current)

    }, 300);
  }, [isMobile])

  const menuStart = useCallback((runAnim) => {
    if (runAnim) {
      menuIdle.current = false;
      if (isMobile === false) {
        let menuBtn = document.getElementById('menuBtn');
        menuBtn.setAttribute('viewBox', "0 0 1920 1200");
      } else {
        let menuBtn_m = document.getElementById('menuBtn_m');
        menuBtn_m.setAttribute('viewBox', "0 0 600 1200");
        
      }
        
      gsap.to("#menuWrap", {height: "100vh", duration: 0});
      // console.log('menuStart', runAnim, isMobile, menuIdle.current)
    }
    
    
  }, [isMobile])

  const closeMenu = useCallback(() => {
    setMenuOn(false);
    // console.log('closeMenu 0', buttonColorRef.current, accentColor)
    if (buttonColorRef.current) {
      
      if (isMobile === false) {
        var tl = gsap.timeline({
          defaults: {duration: 0.4, ease: "power2"},
          onStart: menuStart(false),
          onComplete: menuComplete(true)
        });
        tl.to("#menu", {opacity:0, duration: 0.2});
        tl.to('#menuTriangle1', {morphSVG: "#menuTriangle1"}, "<");
        tl.to('#menuTriangle2', {morphSVG: "#menuTriangle2"}, "<");
        tl.to("#menuGradient1 stop:nth-child(1)", {stopColor: buttonColorRef.current});
        tl.to("#menuGradient1 stop:nth-child(2)", {stopColor: buttonColorRef.current}, "<");
        tl.to('#bar1', {morphSVG: "M1830,29.29h62", stroke: (buttonColorRef.current === "#FFFFFF")?"#000000":"#FFFFFF"}, "<-0.5");
        tl.to('#bar2', {morphSVG: "M1856.1,52.59h35.9", stroke: (buttonColorRef.current === "#FFFFFF")?"#000000":"#FFFFFF"}, "<");
        tl.to('#bar3', {opacity: 1, duration: 0.25, ease: "none"}, "<+0.5");
        menuOpenRef.current = false;
        // console.log('closeMenu', buttonColorRef.current, isMobile)
      } else {
        // console.log('closeMenu mobile', buttonColorRef.current, isMobile)
        var tl_m = gsap.timeline({
          defaults: {duration: 0.4, ease: "power2"},
          onStart: menuStart(false),
          onComplete: menuComplete(true)
        });
        tl_m.to("#menu", {opacity:0, duration: 0.2});
        tl_m.to('#menuTriangle1_m', {morphSVG: "#menuTriangle1_m"}, "<");
        tl_m.to('#menuTriangle2_m', {morphSVG: "#menuTriangle2_m"}, "<");
        tl_m.to("#menuGradient1_m stop:nth-child(1)", {stopColor: buttonColorRef.current});
        tl_m.to("#menuGradient1_m stop:nth-child(2)", {stopColor: buttonColorRef.current}, "<");
        tl_m.to('#bar1_m', {morphSVG: "M510,30h62", stroke: (buttonColorRef.current === "#FFFFFF")?"#000000":"#FFFFFF"}, "<-0.5");
        tl_m.to('#bar2_m', {morphSVG: "M536,53.3h36", stroke: (buttonColorRef.current === "#FFFFFF")?"#000000":"#FFFFFF"}, "<");
        tl_m.to('#bar3_m', {opacity: 1, duration: 0.25, ease: "none"}, "<+0.5");
        menuOpenRef.current = false;
        
      }
    }
    
  }, [menuStart, menuComplete, isMobile])


  const handleMenuBtn = useCallback((menuIsOpen) => {
    // console.log('handleMenuBtn 0')
    if (!menuIsOpen) {
      // console.log('handleMenuBtn Close 1', menuIsOpen, menuIsOpen, menuIdle.current)

      if (menuIdle.current) {
        // console.log('handleMenuBtn Close 1.1', menuIsOpen, menuIsOpen, menuIdle.current)
        closeMenu(); 

      }
    } else {
      // console.log('handleMenuBtn Open 0', menuIsOpen, menuIsOpen)
      if (menuIsOpen) {
        // console.log('handleMenuBtn Open 0.1', menuIsOpen, menuIsOpen)
        
        setMenuOn(true);
        if (isMobile === false) {
          var t2 = gsap.timeline({
            defaults: {duration: 0.4, ease: "power2"},
            onStart: menuStart(true),
            onComplete: menuComplete(false)
          });
      
          t2.to('#menuTriangle1', {morphSVG: "#menuOpen1"}, "<");
          t2.to('#menuTriangle2', {morphSVG: "#menuOpen2"}, "<");
          t2.to('#bar3', {opacity: 0, duration: 0.15, ease: "none"}, "<");
          t2.to("#menuGradient1 stop:nth-child(1)", {stopColor: "#000000"}, "<");
          t2.to("#menuGradient1 stop:nth-child(2)", {stopColor: "#000000"}, "<");
          t2.to('#bar1', {morphSVG: "M1830,90 L1891,30", stroke: "#FFFFFF"}, "<");
          t2.to('#bar2', {morphSVG: "M1830,30 L1891,90", stroke: "#FFFFFF"}, "<");
          t2.to("#menu", {opacity: 1});
        } else {
          let corner = document.getElementById('cornerIn');
          corner.removeAttribute('filter');
          var t2_m = gsap.timeline({
            defaults: {duration: 0.4, ease: "power2"},
            onStart: menuStart(true),
            onComplete: menuComplete(false)
          });
          t2_m.to('#menuTriangle1_m', {morphSVG: "#menuOpen1_m"}, "<");
          t2_m.to('#menuTriangle2_m', {morphSVG: "#menuOpen2_m"}, "<");
          t2_m.to('#bar3_m', {opacity: 0, duration: 0.15, ease: "none"}, "<");
          t2_m.to("#menuGradient1_m stop:nth-child(1)", {stopColor: "#000000"}, "<");
          t2_m.to("#menuGradient1_m stop:nth-child(2)", {stopColor: "#000000"}, "<");
          t2_m.to('#bar1_m', {morphSVG: "M510,90 L570,30", stroke: "#FFFFFF"}, "<");
          t2_m.to('#bar2_m', {morphSVG: "M510,30 L570,90", stroke: "#FFFFFF"}, "<");
          t2_m.to("#menu", {opacity: 1});
        }
        }
       

    }
  }, [isMobile, menuStart, menuComplete, closeMenu]);

  function postRouteChange(){
    closeMenu();
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    setAccentColor(null);
    
  }


  useEffect(() => {
   if (accentColor) {
      
      buttonColorRef.current = accentColor;
      // console.log('accentColor', accentColor, buttonColorRef.current)
      if (isMobile === false) {
        gsap.to('#bar1', {stroke: (buttonColorRef.current === "#FFFFFF")?"#5a5a5a":"#FFFFFF"});
        gsap.to('#bar2', {stroke: (buttonColorRef.current === "#FFFFFF")?"#5a5a5a":"#FFFFFF"});
        gsap.to('#bar3', {stroke: (buttonColorRef.current === "#FFFFFF")?"#5a5a5a":"#FFFFFF"});
        gsap.to("#menuGradient1 stop:nth-child(1)", {stopColor: buttonColorRef.current});
        gsap.to("#menuGradient1 stop:nth-child(2)", {stopColor: buttonColorRef.current});
      } else {
        
        gsap.to('#bar1_m', {stroke: (buttonColorRef.current === "#FFFFFF")?"#5a5a5a":"#FFFFFF"});
        gsap.to('#bar2_m', {stroke: (buttonColorRef.current === "#FFFFFF")?"#5a5a5a":"#FFFFFF"});
        gsap.to('#bar3_m', {stroke: (buttonColorRef.current === "#FFFFFF")?"#5a5a5a":"#FFFFFF"});
        gsap.to("#menuGradient1_m stop:nth-child(1)", {stopColor: buttonColorRef.current});
        gsap.to("#menuGradient1_m stop:nth-child(2)", {stopColor: buttonColorRef.current});

        
      }

      if (buttonColorRef.current === accentColor) {
        closeMenu();
        setYPos(0);
      } 
      
   }
    
  }, [accentColor])

  useEffect(() => {
    let menuCornerBtn_m;
    let menuCornerBtn;
    let menuBtn_mL;
    let menuBtnL;
    
    if (isMobile !== null) {
      if (isMobile === false) {
        // console.log('setup menu btn listener')
        menuCornerBtn = document.getElementById('menuCornerBtn')
        menuBtnL = menuCornerBtn.addEventListener("click", function(){ 
          
          if (menuOpenRef.current) {
            menuOpenRef.current = false;
          } else {
            menuOpenRef.current = true;
          }
          handleMenuBtn(menuOpenRef.current); 
          // console.log("menu click")
      });
  
    } else {
      console.log('setup menu btn listener: mobile')
      menuCornerBtn_m = document.getElementById('menuCornerBtn_m')
      menuBtn_mL = menuCornerBtn_m.addEventListener("click", function(){ 
        
        if (menuOpenRef.current) {
          menuOpenRef.current = false;
        } else {
          menuOpenRef.current = true;
        }
        handleMenuBtn(menuOpenRef.current); 
    });
    }
    }
     
  return () => {
    menuCornerBtn && menuBtnL && menuCornerBtn.removeEventListener('click', menuBtnL);
    menuCornerBtn_m && menuBtn_mL && menuCornerBtn_m.removeEventListener('click', menuBtn_mL);
  }

  }, [isMobile, handleMenuBtn])

  
  useEffect(() => {
    menuOpenRef.current = false;
    buttonColorRef.current = "#FFFFFF";
    // setAccentColor("#FFFFFF")
    // MorphSVGPlugin.convertToPath("#menuTriangle2");
    document.title = configData.BASE_TITLE
    document.querySelector('meta[name="description"]').setAttribute("content", "");
    
    MorphSVGPlugin.convertToPath("polygon");
    axios({
      method: 'get',
      url: configData.API_URL + "/api/capability-list/"
      })
      .then(response => {
        const dataJSON = response.data;
        setCapabilities(dataJSON);
      });

    setLoaded(true)

    // gsap.timeline({
    //   scrollTrigger: {
    //     trigger: ".app",
    //     start: "top top",
    //     end: "8000px",
    //     scrub: 0,
    //     markers: false,
    //     onToggle: self => setNavSm(self.isActive)
    //   }
    // });

    var width = document.documentElement.clientWidth;
    // console.log('width')
    if (width > 600) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }

    // function cssLoaded() {
    //   var cssElem = document.getElementById(cssId);
    //   cssElem.rel='stylesheet';
    //   console.log('cssElem', cssElem)
    // }

    var cssId = 'cssFonts';
    var head  = document.getElementsByTagName('head')[0];
    var cssElem = document.getElementById(cssId);
    if (!cssElem)
    {
        var link  = document.createElement('link');
        link.id   = cssId;
        link.rel  = 'stylesheet';
        link.type = 'text/css';
        link.href = 'https://cloud.typography.com/7604932/7997632/css/fonts.css';
        link.media = 'all';
        link.onload = function(){ setFontsReady(true) };
        head.appendChild(link);
    }  


    var cookie = Cookies.get('_udxdworkid');
    if (cookie) {      
      axios({
        method: 'get',
        baseURL: configData.API_URL,
        url: '/auth/users/me/',
        headers: {
            Authorization: 'Token ' + cookie
        }
        })
        .then(response => {
          console.log('response', response, response.data['username']);

          setUsername(response.data['username'])
          
        })
        .catch(function (error) {
          if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          } else if (error.request) {
          console.log(error.request);
          } else {
          console.log('Error', error.message);
          }
          console.log(error.config);
          setUsername(null);
     
      });
    } else {
      setUsername(null);
    }

  }, []);

  function handleSubNav() {
  
    let t = document.getElementById('subBtnEle');
 
    if (t.classList.contains('open')) {
        
    } else {

    }

    t.classList.toggle('open');
   
  }

  function signOut() {

    var dataJSON = {
      "username": username,
    }
    console.log('data', dataJSON)

     axios({
        method: 'post',
        url: configData.API_URL + "/sign-out/",
        data: dataJSON,
        headers: {
          Authorization: 'Token ' + Cookies.get('_udxdworkid')
        },
    })
    .then(response => {
        // fetchNarratives();
        // setFetchPage(Config.baseURL + '/api/cms/narratives/?page=1');
        // setTimeout(() => {
        //   closeMenu();
        // }, 500);
        

        window.location.href = "/"
    })
    .catch(error => {
        console.log('error', error.response);
    });

 
  }


  return (

      <div className={"app" + ((menuOn)?(" menuOn"):(""))}>
        <header className={"column " + ((navSm)?(" navSm"):(""))} id="top">
          <a className="skip-main" href="#core" aria-label="skip to main" role="navigation">Skip to main content</a>
          <Link aria-label="home" id="logo" to="/" onClick={() => handleMenuBtn(false)}><Logo className={logoColor} /></Link>
          <div className="menuWrap" id="menuWrap">
            <section id="menu" className="mainMenu"> 
              <nav aria-label="top nav">
                <ul>
                  <li className="long" id="subBtnEle">
                  { isMobile ? <span onClick={(e) => handleSubNav(e)} className="subNavBtn">What We Do <span className="caret"><svg width="30" height="30"><polyline points="30,9.11 15,24.11 0,9.11" stroke="#FFFFFF" strokeWidth="1.4142" />
               </svg>
               </span></span> : <span>What We Do</span> }
                    <ul className="subNav" id="subNav">
                      {capabilities && capabilities.map((obj, i) => (
                        <li key={"menuSub1_" + i}>
                          <Link to={obj.get_absolute_url} onClick={() => postRouteChange()}>{obj.heading.replace(/(<([^>]+)>)/gi, "")}</Link>
                        </li>
                      ))}
                    </ul>  
                  </li>
                  <li><Link to="/about" onClick={() => postRouteChange()}>About</Link></li>
                  <li><Link to="/case-studies" onClick={() => postRouteChange()}>Case Studies</Link></li>
                  
                  <li><Link to="/contact" onClick={() => postRouteChange()}>Contact</Link></li>
                  {username && <li> <a href="/work">Work</a></li>}
                  <li className="clientLogin">
                    {!username ? <a href="/signin">Sign In</a>:<span className="ui" onClick={() => signOut()}>Sign Out</span>}
                    
                    
                    </li>
                   <li className="socials">
                    <a href="https://www.linkedin.com/company/udxd/" target="_blank" rel="
                    noreferrer"><LinkedIn alt="linkedIn" className="socialIcon" /></a>
                    <a href="https://www.instagram.com/udxdteam/" target="_blank" rel="
                    noreferrer"><Instagram alt="Instagram" className="socialIcon" /></a>
                    <a href="https://www.behance.net/udxd" target="_blank" rel="
                    noreferrer"><Behance alt="Behance" className="socialIcon" /></a>
                  </li>
                </ul>
              
              </nav>    
            </section>
              
            <svg id="menuBtn_m" viewBox="0 0 600 160" className={(isMobile)?("on"):("off")}>
              <defs>
              <filter id="dropShadow" data-name="drop-shadow-1" filterUnits="userSpaceOnUse">
                <feOffset dx="0" dy="0"/>
                <feGaussianBlur result="uuid-a572c2d0-96d4-4cc7-bb02-2cb0ae669561" stdDeviation="4"/>
                <feFlood floodColor="#000" floodOpacity=".75"/>
                <feComposite in2="uuid-a572c2d0-96d4-4cc7-bb02-2cb0ae669561" operator="in"/>
                <feComposite in="SourceGraphic"/>
              </filter>
              <linearGradient id="menuGradient1_m" x1="600" y1="0" x2="600" y2="1200" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor={buttonColorRef.current} />
                <stop offset="1" stopColor={buttonColorRef.current} />
              </linearGradient>
              <linearGradient id="menuGradient2_m" x1="600" y1="0" x2="600" href="#menuGradient1_m"/>
            </defs>
            <g id="menuOpenGroup_m">
              <polygon id="menuOpen1_m" points="0 1200 600 0 600 1200 0 1200" fill="url(#menuGradient1_m)"/>
              <polygon id="menuOpen2_m" points="0 1200 0 0 600 1.11 .31 1200 0 1200" fill="url(#menuGradient2_m)"/>
            </g>
            <g id="menuCornerBtn_m" >
              <g id="cornerIn" filter="url(#dropShadow)">
                <polygon id="menuTriangle2_m" className="menuTriangle" points="525 75.79 450 .79 600 .79 525 75.79" fill="url(#menuGradient2_m)" data-fill="url(#menuGradient2_m)"/>
                <polygon id="menuTriangle1_m" className="menuTriangle" points="524.74 75.53 600 0 600 150.79 524.74 75.53" fill="url(#menuGradient1_m)" data-fill="url(#menuGradient1_m)"/>
              </g>
              <g id="bars_m">
                <path id="bar1_m" d="M510,29.29h62" fill="none" stroke={(accentColor === "#FFFFFF")?"#5a5a5a":"#FFFFFF"} strokeWidth="2"/>
                <path id="bar2_m" d="M536.1,52.59h35.9" fill="none" stroke={(accentColor === "#FFFFFF")?"#5a5a5a":"#FFFFFF"} strokeWidth="2"/>
                <path id="bar3_m" d="M558,75.79h14" fill="none" stroke={(accentColor === "#FFFFFF")?"#5a5a5a":"#FFFFFF"} strokeWidth="2"/>
              </g>
            </g>
          </svg>

            <svg id="menuBtn" viewBox="0 0 1920 160" className={(isMobile)?("off"):("on")}>
              <defs>
                <filter id="menuGradient" data-name="drop-shadow-1" filterUnits="userSpaceOnUse">
                  <feOffset dx="0" dy="0"/>
                  <feGaussianBlur result="uuid-a572c2d0-96d4-4cc7-bb02-2cb0ae669561" stdDeviation="4"/>
                  <feFlood floodColor="#000" floodOpacity=".75"/>
                  <feComposite in2="uuid-a572c2d0-96d4-4cc7-bb02-2cb0ae669561" operator="in"/>
                  <feComposite in="SourceGraphic"/>
                </filter>
                <linearGradient id="menuGradient1" x1="960" y1="0" x2="960" y2="1080" gradientUnits="userSpaceOnUse">
                  <stop offset="0" stopColor={accentColor} />
                  <stop offset="1" stopColor={accentColor} />
                </linearGradient>
                <linearGradient id="menuGradient2" x1="960" y1="0" x2="960" href="#menuGradient1"/>
              </defs>
              <g id="menuOpenGroup">
                <polygon id="menuOpen1" points="-1 1081 1921 -1 1921 1081 -1 1081" fill="url(#menuGradient1)"/>
                <polygon id="menuOpen2" points="-1 1081 -1 -1 1921 0 0 1081" fill="url(#menuGradient2)"/>
              </g>
              <g id="menuCornerBtn">
            
              <g>
                <polygon id="menuTriangle2" className="menuTriangle" fill="url(#menuGradient2)" data-fill="url(#menuGradient2)" points="1845 75.79 1770 .79 1920 .79 1845 75.79"/>
                <polygon id="menuTriangle1" className="menuTriangle" fill="url(#menuGradient1)" data-fill="url(#menuGradient1)" points="1844 75 1920 0 1920 150 1844 75"/>

              </g>

              <g id="bars">
                <path id="bar1" data-name="bar1" d="M1830,29.29h62" fill="none" stroke={(accentColor === "#FFFFFF")?"#000000":"#FFFFFF"} strokeWidth="2"/>
                <path id="bar2" data-name="bar2" d="M1856.1,52.59h35.9" fill="none" stroke={(accentColor === "#FFFFFF")?"#000000":"#FFFFFF"} strokeWidth="2"/>
                <path id="bar3" data-name="bar3" d="M1878,75.79h14" fill="none" stroke={(accentColor === "#FFFFFF")?"#000000":"#FFFFFF"} strokeWidth="2"/>
              </g>
            </g>
            </svg>
        
          </div>
        </header>
       
        <section id="core">
       
          <Routes>
            <Route path="/" index element={<Home fonts={fontsReady} isMobile={isMobile} logoColor={setLogoColor} accentColor={setAccentColor} footerColor={setFooterClass} />} />
            <Route path="about/" element={ <About fonts={fontsReady} isMobile={isMobile} menuColor={setMenuClass} accentColor={setAccentColor} logoColor={setLogoColor} footerColor={setFooterClass} />} />
            <Route path="contact/" element={ <Contact fonts={fontsReady} isMobile={isMobile} menuColor={setMenuClass} accentColor={setAccentColor} logoColor={setLogoColor} footerColor={setFooterClass} />} />
            <Route path="capabilities/" element={ <Capabilities fonts={fontsReady} isMobile={isMobile} menuColor={setMenuClass} accentColor={setAccentColor} logoColor={setLogoColor} footerColor={setFooterClass} />} /> 
            <Route path="capabilities/:capabilitySlug" element={ <Capabilities fonts={fontsReady} isMobile={isMobile} menuColor={setMenuClass} accentColor={setAccentColor} logoColor={setLogoColor} footerColor={setFooterClass}/>} />
            <Route path="case-studies" element={ <CaseIndex fonts={fontsReady} isMobile={isMobile} menuColor={setMenuClass} accentColor={setAccentColor} logoColor={setLogoColor} footerColor={setFooterClass} />} />
            <Route path="case-studies/:caseSlug" element={ <Case fonts={fontsReady} isMobile={isMobile} menuColor={setMenuClass} accentColor={setAccentColor} logoColor={setLogoColor} footerColor={setFooterClass}/>} />
            <Route path="terms-of-use/" element={ <Legal fonts={fontsReady} isMobile={isMobile} menuColor={setMenuClass} accentColor={setAccentColor} logoColor={setLogoColor} footerColor={setFooterClass} /> } />
            <Route path="work" element={ <WorkIndex setIsStaff={setIsStaff} isStaff={isStaff} fonts={fontsReady} isMobile={isMobile} menuColor={setMenuClass} accentColor={setAccentColor} logoColor={setLogoColor} footerColor={setFooterClass} username={username} />} />
            <Route path="work/:workSlug" element={ <Work fonts={fontsReady} isMobile={isMobile} menuColor={setMenuClass} accentColor={setAccentColor} logoColor={setLogoColor} footerColor={setFooterClass}  setIsStaff={setIsStaff} isStaff={isStaff} />} />
            <Route path="signin/" element={ <SignIn setIsStaff={setIsStaff} setUsername={setUsername} fonts={fontsReady} isMobile={isMobile} menuColor={setMenuClass} accentColor={setAccentColor} logoColor={setLogoColor} footerColor={setFooterClass} />} />
          </Routes>
       
      
        </section>
        <footer className={footerClass}>
     
              <section className="contactInfo">
                <Link aria-label="home" id="logo_footer" to="/" onClick={() => handleMenuBtn(false)}><Logo className={'fillWhite'} /></Link>
                <a href="mailto:info@udxd.co">info@udxd.co</a>
                <a href="tel:+12126478500">212 647-8500</a>
                <span>UDXD<br/>275 Grand Street, Floor 4<br/>New York, New York 10002</span>
                <a href="https://www.udxd.co">udxd.co</a>
                
                <div className="copyright">
                
                <Link to={'/terms-of-use/'}>Terms of Use</Link>
                <div>&copy; {new Date().getFullYear()} UDXD</div> 
              </div>
              </section>
   


              {!isMobile && <section id="footerMenu" className="mainMenu"> 
             
              <nav aria-label="footer nav">
                <ul>
                
                  <li className="long">
                    <span>What We Do</span>
                    <ul>
                      {capabilities && capabilities.map((obj, i) => (
                        <li key={"menuSub1_" + i}>
                          <Link to={obj.get_absolute_url} onClick={() => postRouteChange()}>{obj.heading.replace(/(<([^>]+)>)/gi, "")}</Link>
                        </li>
                      ))}
                    </ul>  
                  </li>
               
                  <li><Link to="/about" onClick={() => postRouteChange()}>About</Link></li>
                  <li><Link to="/case-studies" onClick={() => postRouteChange()}>Case Studies</Link></li>
                 
                  <li><Link to="/contact" onClick={() => postRouteChange()}>Contact</Link></li>
                  <li className="clientLogin"><a href="https://www.udxd.co/signin" onClick={() => postRouteChange()}>Sign In</a></li>

                  <li className="socials">
                    <a href="https://www.linkedin.com/company/udxd/" target="_blank" rel="noreferrer"><LinkedIn alt="linkedIn" className="socialIcon" /></a>
                    <a href="https://www.instagram.com/udxdteam/" target="_blank" rel="noreferrer"><Instagram alt="Instagram" className="socialIcon" /></a>
                    <a href="https://www.behance.net/udxd" target="_blank" rel="noreferrer"><Behance alt="Behance" className="socialIcon" /></a>
                  </li>
                </ul>
                        
              </nav>
              
                        

             
                        
            </section>}
              
    

        </footer>
      </div>

  );
}

export default App;