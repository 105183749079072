import React, { useState, useEffect } from "react";
import {
  Link,
  useParams
} from "react-router-dom";
import { gsap } from "gsap";
import axios from "axios";
import Cookies from 'js-cookie';
// import Modal from './module.modal';
// import {ReactComponent as Logo} from "../static/svg/logo.svg" 
import configData from "../config.json";

function Signin(props) {

    const [formError, setFormError] = useState();

    function postLogin() {

        var username = document.getElementById('username');
        var password = document.getElementById('password');
        console.log('username', username.value, password.value);
        if (username.value && password.value) {
          axios({
            method: 'post',
            baseURL: configData.API_URL,
            url: '/api-token-auth/',
            data: {
              'username': username.value, 
              'password': password.value
            }
            })
            .then(response => {

                console.log('response', response);
                Cookies.set('_udxdworkid', response['data'].token);
                props.setUsername(response.data['username'])
                props.setIsStaff(response.data['is_staff'])
                
                axios({
                    method: 'get',
                    baseURL: configData.API_URL,
                    url: '/auth/users/me/',
                    headers: {
                        Authorization: 'Token ' + Cookies.get('_udxdworkid')
                    }
                    })
                    .then(response => {
                      console.log('response', response)
                      window.location.href = "/work/";
                    });
                          
            })
            .catch(function (error) {
              if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              } else if (error.request) {
              console.log(error.request);
              } else {
              console.log('Error', error.message);
              
              }
              console.log(error.config);


              let newError = {
                "heading": "Error",
                "text": error.response.data['non_field_errors'],
                // "btn2Text": "Cancel",
                // "onBtn2Click": setFormError,
                // "onBtn2": null
              };
              setFormError(newError);


          });
        }
		
    }


    function submitForm(e) {
      if (e.key === "Enter") {
        console.log('enter')
        postLogin()
      }
    
    }

    useEffect(() => {
      gsap.to('#core', {backgroundColor: '#eee', duration: 0.3})

      props.menuColor('none');
      props.accentColor('#FFFFFF');
      props.logoColor('fillBlack');
      // Page Meta
      document.title = "Work Index | " + configData.BASE_TITLE
      document.querySelector('meta[name="description"]').setAttribute("content", "");
      document.documentElement.scrollTop = 0;

      // Load Content
     

    }, []);


    return (
      <section className="main pageItem-enter-active" id="loginFormPage" onKeyUp={(e) => submitForm(e)}>

        
          <div className="loginForm">
            <h1>Sign In</h1>
            <div className="formField">
                <input autoComplete="off" type="text" name="username" id="username" required placeholder="username" />
            </div>

            <div className="formField">
                <input type="password" name="password" id="password" required placeholder="password" />
            </div>
            <div className="formField">
                <input type="submit" className="btn1 submitBtn" value="Sign In" onClick={()=>postLogin()} id="loginSubmit" />
               
            </div>

          </div>
         
 
      </section>
    );
}

export default Signin;

// <a href="/reset-password/" className="reset">Reset Password</a>

// {(formError)&&<Modal heading={formError.heading} text={formError.text} onBtn1Click={setFormError} onBtn1={null} btn1Text={"OK"} onBtn2Click={formError.onBtn2Click} onBtn2={formError.onBtn2} btn2Text={formError.btn2Text} />}