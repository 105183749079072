import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { ReactComponent as ArrowRight } from "../static/svg/arrow-right.svg";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/mousewheel';
import 'swiper/css/free-mode';

import configData from "../config.json";

function getMedia(path) {
  return configData.API_URL + path;
}

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

function sizeOverlay(){
  let colorOverlays = document.getElementsByClassName('colorOverlay');
  for (var i = 0; i < colorOverlays.length; i++) {
    let w = colorOverlays[i].offsetWidth;
    let h = colorOverlays[i].offsetHeight;
    let o = colorOverlays[i].parentElement.getElementsByClassName('overlay')[0];
    o.style.width = w + "px";
    o.style.height = h + "px";
  }
}

function Contact(props) {

    const [loaded, setLoaded] = useState(false);
    const [objs, setObjs] = useState();
    const [industries, setIndustries] = useState();
    const [recentWorkIntro, setRecentWorkIntro] = useState();
    const [values, setValues] = useState();
    const [clientIntro, setClientIntro] = useState();
    const [peopleIntro, setPeopleIntro] = useState();

    useEffect(() => {
      let rowTl;
      if (objs) {
         // Anim Sequences
         let rowTl = gsap.timeline({
            defaults: {
              duration: 0.6, 
              ease: "power1",
            },
            // scrollTrigger: {
            //   trigger: "#row0trigger",
            //   start: "top top",
            //   end: "+=100",
            //   scrub: 1,
            //   markers: true
            // }
          });
        //  rowTl.fromTo(".anim0.row0", {translateY: "40px"}, {translateY: "0"});
        //  rowTl.fromTo(".anim0.row0", {opacity: 0}, {opacity: 1},"<");
        //  rowTl.fromTo(".anim0.row0-1a", {translateY: "40px"}, {translateY: "0"});
        //  rowTl.fromTo(".anim0.row0-1a", {opacity: 0}, {opacity: 1},"<");
        //  rowTl.fromTo(".anim0.row0-1b", {translateY: "40px"}, {translateY: "0"});
        //  rowTl.fromTo(".anim0.row0-1b", {opacity: 0}, {opacity: 1},"<");

      }
      return () => {
        rowTl && rowTl.kill();
      }
    }, [objs])

    useEffect(() => {
        if (objs) {
            
            console.log('case studies loaded');    
        }
    }, [objs])

    useEffect(() => {
      gsap.to('#core', {backgroundColor: '#ffffff', duration: 0.3})
      props.menuColor('none');
      props.accentColor('#FFFFFF');
      props.logoColor('fillBlack');
      // Page Meta
      document.title = "Contact | " + configData.BASE_TITLE
      document.querySelector('meta[name="description"]').setAttribute("content", "");
      document.documentElement.scrollTop = 0;

      // Load Content
      setLoaded(true)
  
    }, []);



  return (
      <CSSTransition in={loaded} timeout={200} unmountOnExit classNames="pageItem">
         <div id="legalPage">
          <div className="column">
          
           <div className="row">
                <div className="heading">
                    <h1>Credits/Legal</h1>
                </div>
                <h2>Personal Information and Data Tracking</h2>
                <p>We may collect and store personal information (such as name and other contact details) that you choose to provide to us through the site or when you contact us. The types of personal information we collect may include:</p>
                <ul>
                  <li>Contact information, such as your name, e-mail address, and telephone number;</li>
                  <li>When you apply for a job, your name, email address, phone number, gender, race, and any other information you may provide in your cover letter or resume; and</li>
                  <li>Other personal information you may submit to us, such as when you contact us.</li>
                </ul>
                <p>We also use third-party online analytics services, such as Google Analytics. The service providers that administer these analytics services use cookies and similar technologies to collect and analyze information about use of the Site and report on activities and trends and diagnose technical issues.</p>
                <br></br>
                <br></br>
                <h2>Privacy Policy</h2>
                <p>This Website and all of its content: text, graphics, audio, video, photos and other data (collectively, the 'Content') are the copyrighted works of UDXD and it's affiliates (Union Design, LLC) and/or various third parties. The content is for your personal and non-commercial use only. You may not download our content for commercial purposes without prior written permission of UDXD.</p>
                <p>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this Website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</p>
                <br></br>
                <br></br>
                <h2>Terms and Conditions</h2>
                <p>UDXD offers this Website, including all information, software, products and services available from this Website or offered as part of or in conjunction with this Website (the “Website”), to you, the user, conditioned upon your acceptance of all of the terms, conditions, policies and notices stated here. Full details of our Terms and Conditions are available on request.</p>
                <p>Your continued use of the Website constitutes your agreement to all such terms, conditions and notices, and any changes to the Terms and Conditions made by UDXD.
                </p>
                <p>The term 'udxd.co' or 'us' or 'we' refers to the owner of the Website. The term 'you' refers to the user or viewer of our Website.</p>
                <p>The use of this Website is subject to the following terms of use:</p>
                <p>Use the Website at your own risk. This Website is provided to you “as is,” without warranty of any kind either express or implied. Neither UDXD nor its employees, agents, third-party information providers, merchants, licensors or the like warrant that the Website or its operation will be accurate, reliable, uninterrupted or error-free. No agent or representative has the authority to create any warranty regarding the Website on behalf of UDXD. UDXD reserves the right to change or discontinue at any time any aspect or feature of the Website.</p>
            </div>
            
          </div>
        </div>
      </CSSTransition>
  );
}

export default Contact;