import React, { useState, useEffect, Fragment } from "react";
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AspectRatioBox from "./aspectRatioUtil";
import { getStatic } from '../static';

import SwiperCore, { Navigation, Pagination, Mousewheel, EffectFade, Autoplay, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/mousewheel';
import 'swiper/css/effect-fade';


import configData from "../config.json";

function getMedia(path) {
  return configData.API_URL + "/media/" + path;
}

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

function sizeOverlay(){
  let colorOverlays = document.getElementsByClassName('colorOverlay');
  for (var i = 0; i < colorOverlays.length; i++) {
    let w = colorOverlays[i].offsetWidth;
    let h = colorOverlays[i].offsetHeight;
    let o = colorOverlays[i].parentElement.getElementsByClassName('overlay')[0];
    o.style.width = w + "px";
    o.style.height = h + "px";
  }
}

function Work(props) {


    let {workSlug} = useParams();

    const [loaded, setLoaded] = useState(false);
    const [obj, setObj] = useState();

    const getObj = async() => {
      await axios({
        method: 'get',
        url: configData.API_URL + "/api/work/" + workSlug
        })
        .then(response => {
          const dataJSON = response.data;
          setObj(dataJSON);

          console.log('work obj', dataJSON)
        });
    }

    useEffect(() => {
      
      let rowTl;
      if (obj) {

        document.title = obj.title + " | " + configData.BASE_TITLE
        document.querySelector('meta[name="description"]').setAttribute("content", "");
        document.documentElement.scrollTop = 0;
        
        setLoaded(true);
        console.log('case studies loaded');    
        
       if (obj.background_color) {
        handleBackground(obj.background_color)
       }
        

        // objs.forEach((o, i) => {

        //  if (props.isMobile) {
        //   let rowTl = gsap.timeline({
        //     defaults: {
        //       duration: 0.6, 
        //       ease: "power1",
        //     },
        //     scrollTrigger: {
        //       trigger: "#caseId_" + o.id,
        //       start: "top 60%",
        //       end: "+=100",
        //       // start: "center center",
        //       // end: "top top",
        //       scrub: 1,
        //       markers: false
        //     }
        //   });
        //  rowTl.fromTo("#caseId_" + o.id, {translateY: "40px"}, {translateY: "0"});
        //  rowTl.fromTo("#caseId_" + o.id, {opacity: 0}, {opacity: 1},"<");
        //  } else {
        //   let rowTl = gsap.timeline({
        //     defaults: {
        //       duration: 0.6, 
        //       ease: "power1",
        //     },
        //     scrollTrigger: {
        //       trigger: "#caseId_" + o.id,
        //       start: "top 80%",
        //       end: "+=100",
        //       scrub: 1,
        //       markers: false
        //     }
        //   });
        //  rowTl.fromTo("#caseId_" + o.id, {translateY: "40px"}, {translateY: "0"});
        //  rowTl.fromTo("#caseId_" + o.id, {opacity: 0}, {opacity: 1},"<");

        //  }
          
        // });
        

      }
      return () => {
        
        rowTl && rowTl.kill();
      }
    }, [obj, props.isMobile])

    useEffect(() => {
      let rowTl0;
      if (loaded) {
        rowTl0 = gsap.timeline({
          defaults: {
            ease: "power1",
          },
        });
        rowTl0.fromTo("#row0 h1.anim0", {translateY: "40px"}, {translateY: "0"});
        rowTl0.fromTo("#row0 h1.anim0", {opacity: 0}, {opacity: 1},"<");
        rowTl0.fromTo("#row0 h3.anim0", {translateY: "40px"}, {translateY: "0"});
        rowTl0.fromTo("#row0 h3.anim0", {opacity: 0}, {opacity: 1},"<");
        rowTl0.fromTo(".gridWorkRows.anim0", {translateY: "40px"}, {translateY: "0"});
        rowTl0.fromTo(".gridWorkRows.anim0",  {opacity: 0}, {opacity: 1},"<");
      }
      return () => {
        rowTl0 && rowTl0.kill();
       
      }
    }, [loaded])


    useEffect(() => {
      gsap.to('#core', {backgroundColor: '#ffffff', duration: 0.3})
      props.menuColor('none');
      props.accentColor('#FFFFFF');
      props.logoColor('fillBlack');
      // Page Meta
      

      // Load Content
      getObj();
  
    }, []);

    function arrowRight(objId, textColor) {
      return (
        <svg width="15.167" height="15.526" viewBox="0 0 15.167 15.526">
          <defs>
            <clipPath id={"clip-path_" + objId}>
              <path data-name="Path 263" d="M0,0H15.167V-15.526H0Z" fill="none"/>
            </clipPath>
          </defs>
          <g data-name="Group 723" transform="translate(0 15.526)" clipPath={"url(#clip-path_" + objId + ")"}>
            <g data-name="Group 721" transform="translate(7.055 -0.354)">
              <path data-name="Path 261" d="M0,0,7.405-7.405-.009-14.819" fill="none" stroke={textColor} strokeWidth="1"/>
            </g>
            <g data-name="Group 722" transform="translate(0 -7.763)">
              <path data-name="Path 262" d="M0,0H14.2" fill="none" stroke={textColor}  strokeWidth="1"/>
            </g>
          </g>
        </svg>
      )
    }


    function handleBackground(color) {
      gsap.to('#core', {backgroundColor: color, duration: 0.2})
    }
  


  return (
      <CSSTransition in={loaded} timeout={200} unmountOnExit classNames="pageItem">
       <div id="workDetail">
          <div className="column">
          <span className="row" id="row0">
            {obj && props.isStaff && <div className="heading landing">
              <span className="crumbs">
                <ul>
                  <li><a href={"/work/"}>Work</a></li>  
                  <li>|</li>
                  <li><a href={"/work/?parent=" + obj.project_type_parent_slug}>{obj.project_type_parent}</a></li>
                  <li>|</li>
                  <li><a href={"/work/?type=" + obj.project_type_slug}>{obj.project_type}</a></li>
                </ul>
              </span>
              <AspectRatioBox tag={"h1"} classes={["anim0"]} fonts={props.fonts} content={obj.title} />
              <AspectRatioBox tag={"h3"} ariaLevel={2} classes={["anim0"]} fonts={props.fonts} content={obj.client_name + (obj.location ? (" | " + obj.location):"")} />
       
            </div>}
            {obj && !props.isStaff && <div className="heading landing">
              <span className="crumbs">
                <ul>
                  <li><a href={"/work/"}>Work</a></li>  
                  <li>|</li>
                  <li><span className="noLink">{obj.project_type_parent}</span></li>
                  <li>|</li>
                  <li><span className="noLink">{obj.project_type}</span></li>
                </ul>
              </span>
              <AspectRatioBox tag={"h1"} classes={["anim0"]} fonts={props.fonts} content={obj.title} />
              <AspectRatioBox tag={"h3"} ariaLevel={2} classes={["anim0"]} fonts={props.fonts} content={obj.client_name + (obj.location ? (" | " + obj.location):"")} />
       
            </div>}
          </span>

          <div className="grid gridWorkRows anim0">
          {obj && obj.files && obj.files.map((obj, i) => (

            <Fragment key={"file_" + i}>
            {(obj.media_type === 'image') &&  <span className={"file " + obj.width}>
              <img src={getMedia(obj.file)} alt=""  />
            </span>}

            {(obj.media_type === 'video') &&  <span className={"file " + obj.width}>
            {obj.auto_start ? <video width="100%" height="auto" autoPlay playsInline muted loop>
                <source src={getMedia(obj.file)} type="video/mp4" />
              </video>:<video width="100%" height="auto" muted controls>
              <source src={getMedia(obj.file)} type="video/mp4" />
            </video>}
            </span>}

           {(obj.media_type === "gallery") && <div className={"swiperColumn "  + (obj.width)}>
            <Swiper
              modules={[Mousewheel, EffectFade, Autoplay]}
              init={false}
              className={"swiper-slide"}
              effect={'fade'}
              autoplay={{
                delay: 3000
              }}
              mousewheel={{
                forceToAxis: true
              }}
              // onSwiper={(swiper) => addSwiper(swiper)}
              >

              {obj.slideshow && obj.slideshow.map((slide, j) => (

                <SwiperSlide 
                key={"workSlide_" + j}
              > 
            
                  {(slide.media_type === 'image') &&  <span className={"file"}>
                  <img src={getMedia(slide.file)} alt=""  />
                </span>}

                {(slide.media_type === 'video') &&  <span className={"file"}>
              
                <video width="100%" height="auto" playsInline autoPlay muted loop>
                    <source src={getMedia(slide.file)} type="video/mp4" />
                  </video>
                </span>}
                 
                </SwiperSlide>

              ))}
              
              </Swiper>
            </div>}

            </Fragment>

             ))}



          </div>
          
          {obj && obj.related_projects && obj.related_projects.length > 0 && <div className="row relatedProjects">
          <h3>Related Projects</h3>
          <span className="relatedProjectLinks">
          {obj.related_projects.map((obj, i) => (
            <a href={obj.get_absolute_url} key={'rel_' + i}>
              <h3 className="alt">{obj.title}
              {arrowRight("_caseBox_" + i, "#000000")}
              </h3>
            </a>
          ))}
          </span>
          </div>
           }
          </div>
        </div>
      </CSSTransition>
  );
}

export default Work;
// <h3 className="alt anim0"  dangerouslySetInnerHTML={{__html: obj.description}} />
