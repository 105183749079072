import {Fragment} from 'react'

const AspectRatioBox = (props)=>{

    function parseBreaks(input) {
        let array = []
        let r = input.split(';');
        r.forEach((obj, i) => {
            if (props.isMobile) {
                let r;
                if (i === 0) {
                    r = <span key={'h1Span_' + i}>{obj}</span>
                } else {
                    r = <span key={'h1Span_' + i}><span className="pipe">|</span>{obj}</span>
                }

                array.push(r)
            } else {
                array.push(<span key={'h1Span_' + i}>{obj}</span>)
            }
            
        })
        return array
    }

    
    return (
        <Fragment>
        {(props.tag === "h1Array") && <h1 className={"arBox " + (props.classes ? props.classes.join(' '):"") + ((props.fonts)?" on ":" ")}>
            <span className="arBoxIn">
                {props.content && parseBreaks(props.content)}
            </span>
        </h1>}
        {(props.tag === "h1") && <h1 className={"arBox " + (props.classes ? props.classes.join(' '):"") + ((props.fonts)?" on ":" ")}>
            <span className="arBoxIn" dangerouslySetInnerHTML={{__html: ((props.fonts && props.content) ? props.content : "") }}  style={(props.headingAr)?{paddingTop: props.headingAr}:{}} />
        </h1>}
        {(props.tag === "h2") && <h2 className={"arBox" + ((props.fonts)?" on ":" ") + (props.classes ? props.classes.join(' '):"")}  style={(props.headingAr)?{paddingTop: props.headingAr}:{}}>
        {console.log('props.headingAr', props.headingAr)}
            <span className="arBoxIn" dangerouslySetInnerHTML={{__html: ((props.fonts && props.content)? props.content : "") }} />
        </h2>}
        {(props.tag === "h3") && <h3 aria-level={props.ariaLevel ? props.ariaLevel : 3} className={"arBox" + ((props.fonts)?" on ":" ") + (props.classes ? props.classes.join(' '):"")}>
 
            <span className="arBoxIn" dangerouslySetInnerHTML={{__html: ((props.fonts && props.content)? props.content : "") }} />
        </h3>}
        {(props.tag === "p") && <p className={"arBox" + ((props.fonts)?" o n":" ") + (props.classes ? props.classes.join(' '):"")}>
        <span className="arBoxIn" dangerouslySetInnerHTML={{__html: ((props.fonts && props.content)? props.content : "") }} />
         </p>}
         {(props.tag === "h2Inbox") && <h2 className={"arBox" + ((props.fonts)?" on ":" ") + (props.classes ? props.classes.join(' '):"")}>
        <span className="arBoxIn">
           {props.content && <span className="pBox">{props.content}</span> }
        </span>
         </h2>}
        </Fragment>
    )
}

export default AspectRatioBox

// style={(props.headingHeight)?{paddingTop: "calc(" + props.headingHeight + "/92vw * 100%)"}:{}}